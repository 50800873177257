import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/24/outline';
import { timeDifference } from '../utils/helperFunctions';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDropzone } from 'react-dropzone';
import AuthService from '../services/auth/auth_service';

const UpcomingNews = () => {
    const [open, setOpen] = useState(false);
    const [eventDate, setEventDate] = useState(null);
    const [images, setImages] = useState([]);
    const [refreshing, setRefreshing] = useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Handle drag and drop of images
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: (acceptedFiles) => {
            setImages((prevImages) =>
                prevImages.concat(
                    acceptedFiles.map((file) =>
                        Object.assign(file, { preview: URL.createObjectURL(file) })
                    )
                )
            );
        },
    });

    // Remove image from preview
    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    // Handle form submission
    const handleSubmit = async () => {
        console.log('Event Date:', eventDate);
        console.log('Images:', images);

        // Prepare the event date in the correct format
        const formattedDate = eventDate.format('YYYY-MM-DD');

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            // Loop through each image and submit separately
            for (const file of images) {
                const formData = new FormData();
                formData.append('event_date', formattedDate);
                formData.append('image', file);  // The key should match your backend's expected key

                // Post the form data to your backend for each image
                // const response = await axios.post('http://localhost:8000/v1/upcoming-news/create/', formData, config);
                // console.log('Form submitted successfully for image:', response.data);

                AuthService.setUpcomingNews(formData)
                    .then((response) => {
                        if (response.status === 201) {
                            console.log('Form submitted successfully for image:', response.data);
                            fetchEvents();
                        }
                    })
                    .catch((error) => {
                        console.error('Error submitting form for image:', error);
                    });
            }

            handleClose();  // Close the dialog after submission
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [nextPage, setNextPage] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    // Fetch Events Data
    // const fetchEvents = async (isRefresh = false) => {
    //     try {
    //         isRefresh ? setLoading(true) : setLoadingMore(true);

    //         const config = {
    //             headers: {
    //                 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
    //                 "Content-Type": "application/json",
    //             },
    //         };

    //         const response = await axios.get(
    //             isRefresh ? `http://localhost:8000/v1/upcoming-news/` : nextPage || `http://localhost:8000/v1/upcoming-news/`,
    //             config
    //         );

    //         const data = response.data;

    //         setEvents((prevEvents) =>
    //             isRefresh ? data.results : [...prevEvents, ...data.results]
    //         );
    //         setNextPage(data.next);  // Store the next page URL
    //     } catch (error) {
    //         console.error("Error fetching events:", error);
    //     } finally {
    //         setLoading(false);
    //         setLoadingMore(false);
    //     }
    // };





    const fetchEvents = async (page = 1) => {
        setLoading(true);
        // try {
        //     const response = await fetch(`http://localhost:8000/v1/upcoming-news/?page=${page}`, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //         },
        //     });

        //     if (!response.ok) {
        //         throw new Error(`Error fetching news: ${response.statusText}`);
        //     }

        //     const data = await response.json();
        //     console.log("News data:", data);

        //     // Append new data to the existing data

        //     if (page === 1) {
        //         // Replace data when it's the first page (avoid duplication)
        //         setEvents(data.results);
        //     } else {
        //         // Append new data when loading more pages
        //         setEvents(prevData => [...prevData, ...data.results]);
        //     }

        //     // Handle the next page URL
        //     setNextPage(data.next ? page + 1 : null);

        // } catch (error) {
        //     console.error("Error fetching news:", error);
        //     // You can set some error state to notify users
        // } finally {
        //     setLoading(false);
        // }

        AuthService.getUpcomingNews(page)
            .then((response) => {
              if (response.status === 200) {
                const data = response.data;
                if (page === 1) {
                  setEvents(data.results);
                } else {
                  setEvents(prevData => [...prevData, ...data.results]);
                }
                setNextPage(data.next ? page + 1 : null);
              }
            })
            .catch((error) => {
                console.error("Error fetching news:", error);
                // You can set some error state to notify users
            })
            .finally(() => setLoading(false));
    };











    const loadMoreData = () => {
        if (nextPage && !loading) {
            fetchEvents(nextPage); // Fetch the next page of news
        }
    };

    const handleRefresh = () => {
        setRefreshing(true);
        setNextPage(1); // Reset to the first page on refresh
        setEvents([]); // Clear the previous data
        fetchEvents(1).finally(() => setRefreshing(false)); // Fetch fresh data on refresh
    };

    return (
        <div className="w-full h-full p-4 bg-gray-100">
            <div className="bg-white py-6 sm:py-8 lg:py-12">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <div className="mb-6 flex items-end justify-between gap-4">
                        <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">Upcoming News</h2>
                        <button
                            onClick={handleOpen}
                            className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                        >
                            Add more
                        </button>
                    </div>

                    <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
                        {events.map((post, index) => (
                            <div key={post.id}>
                                <a href="#" className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                                    <img src={post.image} loading="lazy" alt="Photo by Austin Wade" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                </a>
                                <div className="flex flex-col">
                                    <span className="text-gray-500">{post.event_date}</span>
                                    <a href="#" className="text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl">Created by</a>
                                </div>
                            </div>
                        ))}


                    </div>


                    {nextPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={loadMoreData}
                                className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                            >
                                Load more
                            </button>
                        </div>
                    )}


                    {/* Modal */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Add Upcoming News</DialogTitle>
                        <DialogContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Event Date"
                                    value={eventDate}
                                    onChange={(newValue) => setEventDate(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth margin="normal" />
                                    )}
                                    fullWidth
                                />
                            </LocalizationProvider>

                            <div className="my-4">
                                {/* <div
                                    {...getRootProps({
                                        className: 'border-dashed border-2 p-4 text-center bg-gray-50',
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <p className="text-gray-500">
                                        Drag and drop images here, or click to select files
                                    </p>
                                </div> */}



                                <div
                                    {...getRootProps()}
                                    className="cursor-pointer p-12 flex justify-center bg-white border border-dashed border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600"
                                >
                                    <input {...getInputProps()} />
                                    <div className="text-center">
                                        <span className="inline-flex justify-center items-center size-16 bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
                                            <svg className="shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                <polyline points="17 8 12 3 7 8"></polyline>
                                                <line x1="12" x2="12" y1="3" y2="15"></line>
                                            </svg>
                                        </span>

                                        <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
                                            <span className="pe-1 font-medium text-gray-800 dark:text-neutral-200">
                                                Drop your file here or
                                            </span>
                                            <span className="bg-white font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600">
                                                browse
                                            </span>
                                        </div>

                                        <p className="mt-1 text-xs text-gray-400 dark:text-neutral-400">
                                            Pick an image up to 2MB.
                                        </p>
                                    </div>
                                </div>



                                {images.length > 0 && (
                                    <div className="mt-4 grid grid-cols-3 gap-4">
                                        {images.map((file, index) => (
                                            <div key={file.name} className="relative">
                                                <img
                                                    src={file.preview}
                                                    alt={file.name}
                                                    className="h-32 w-32 object-cover"
                                                />
                                                <button
                                                    onClick={() => handleRemoveImage(index)}
                                                    className="absolute top-0 right-0 p-1 text-red-500"
                                                >
                                                    ✖
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default UpcomingNews;
