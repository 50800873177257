






import React, { Suspense } from "react";
import {
  Button,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import merge from "deepmerge";
// import Chart from 'react-apexcharts';
import Chart from 'react-apexcharts';


// Dynamic import for Chart component
// const Chart = React.lazy(() => import("react-apexcharts"));

// KPI Card Component
function KpiCard({ title, percentage, price, color, icon }) {
  return (
    <Card  className="shadow-sm border h-[8rem] border-gray-200 !rounded-lg">
      <CardBody className="p-4">
        <div className="flex justify-between items-center">
          <Typography className="!font-medium !text-xs text-gray-600">
            {title}
          </Typography>
          <div className="flex items-center gap-1">
            {icon}
            <Typography color={color} className="font-medium !text-xs">
              {percentage}
            </Typography>
          </div>
        </div>
        <Typography color="blue-gray" className="mt-1 font-bold text-2xl">
          {price}
        </Typography>
      </CardBody>
    </Card>
  );
}

// KPI Data
const kpiData = [
  {
    title: "Total Posts",
    percentage: "12%",
    price: "1,200",
    color: "red",
    icon: (
      <ChevronDownIcon strokeWidth={4} className="w-3 h-3 text-red-500" />
    ),
  },
  {
    title: "Special News",
    percentage: "16%",
    price: "150",
    color: "green",
    icon: (
      <ChevronUpIcon strokeWidth={4} className="w-3 h-3 text-green-500" />
    ),
  },
  {
    title: "Special Tags",
    percentage: "10%",
    price: "80",
    color: "green",
    icon: (
      <ChevronUpIcon strokeWidth={4} className="w-3 h-3 text-green-500" />
    ),
  },
  {
    title: "Visitors",
    percentage: "10%",
    price: "30,000",
    color: "red",
    icon: (
      <ChevronDownIcon strokeWidth={4} className="w-3 h-3 text-red-500" />
    ),
  },
];

// Area Chart Component
function AreaChart({ height = 350, series, colors, options }) {
  const chartOptions = React.useMemo(
    () => ({
      colors,
      ...merge(
        {
          chart: {
            height: height,
            type: "area",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "",
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          markers: {
            size: 0,
            strokeWidth: 0,
            strokeColors: "transparent",
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          grid: {
            show: true,
            borderColor: "#dddddd90",
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: 5,
              right: 20,
            },
          },
          tooltip: {
            theme: "light",
          },
          yaxis: {
            labels: {
              style: {
                colors: "#757575",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 500,
                
              },
            },
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: {
              style: {
                colors: "#757575",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 900,
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.9,
              opacityTo: 0.2,
              stops: [0, 100],
            },
          },
        },
        options ? options : {}
      ),
    }),
    [height, colors, options]
  );

  return (
    <Suspense fallback={<div>Loading chart...</div>}>
      <Chart
        type="area"
        height={height}
        series={series}
        options={chartOptions}
      />
    </Suspense>
  );
}





const chartOptions = {
  chart: {
      height: 300,
      type: 'area',
      toolbar: {
          show: false
      },
      zoom: {
          enabled: false
      }
  },
  series: [
      {
          name: 'Income',
          data: [18000, 51000, 60000, 38000, 88000, 50000, 40000, 52000, 88000, 80000, 60000, 70000]
      },
      {
          name: 'Outcome',
          data: [27000, 38000, 60000, 77000, 40000, 50000, 49000, 29000, 42000, 27000, 42000, 50000]
      }
  ],
  legend: {
      show: false
  },
  dataLabels: {
      enabled: false
  },
  stroke: {
      curve: 'smooth',
      width: 2
  },
  grid: {
      strokeDashArray: 2
  },
  fill: {
      type: 'gradient',
      gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 0.1,
          opacityTo: 0.8
      }
  },
  xaxis: {
      type: 'category',
      tickPlacement: 'on',
      categories: [
          ' January',
          ' February',
          ' March 2023',
          ' April 2023',
          ' May 2023',
          ' June 2023',
          ' July 2023',
          ' August 2023',
          ' September 2023',
          ' October 2023',
          ' November 2023',
          ' December 2023'
      ],
      axisBorder: {
          show: false
      },
      axisTicks: {
          show: false
      },
      labels: {
          style: {
              colors: '#555555',
              fontSize: '13px',
              // fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 900
          },
          formatter: (value) => {
              if (typeof value === 'string') {
                  const t = value.split(' ');
                  return `${t[0]} ${t[1].slice(0, 3)}`;
              }
              return value; // Ensure to return a valid value if it's not a string
          }
      }
  },
  yaxis: {
      labels: {
          align: 'left',
          style: {
              colors: '#999999',
              fontSize: '13px',
              // fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 900
          },
          formatter: (value) => value >= 1000 ? `${value / 1000}k` : value
      }
  },
  tooltip: {
      x: {
          format: 'MMMM yyyy'
      },
      y: {
          formatter: (value) => `$${value >= 1000 ? `${value / 1000}k` : value}`
      }
  },
  responsive: [
      {
          breakpoint: 568,
          options: {
              chart: {
                  height: 300
              },
              yaxis: {
                  labels: {
                      style: {
                          colors: '#9ca3af',
                          fontSize: '11px',
                          // fontFamily: 'Inter, ui-sans-serif',
                          fontWeight: 900
                      },
                      formatter: (value) => value >= 1000 ? `${value / 1000}k` : value
                  }
              }
          }
      }
  ]
};



// Main App Component
function DashboardPage() {
  return (
    <div className="container mx-auto py-10 px-6 bg-slate-100 w-full h-90 ">
      {/* KPI Cards Section */}
      <section className="py-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <Typography className="font-bold">Overall Performance</Typography>
            <Typography
              variant="small"
              className="font-normal text-gray-600 md:w-full w-4/5"
            >
              Upward arrow indicating an increase in performance compared to the
              previous period.
            </Typography>
          </div>
          <div className="shrink-0">
            <Menu>
              <MenuHandler>
                <Button
                  color="gray"
                  variant="outlined"
                  className="flex items-center gap-1 !border-gray-300"
                >
                  Last 7 Days
                  <ChevronDownIcon strokeWidth={4} className="w-3 h-3 text-gray-900" />
                </Button>
              </MenuHandler>
              <MenuList>
                <MenuItem>Last 7 Days</MenuItem>
                <MenuItem>Last 30 Days</MenuItem>
                <MenuItem>Last 90 Days</MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
          {kpiData.map((props, key) => (
            <KpiCard key={key} {...props} />
          ))}
        </div>
      </section>

      {/* Chart Section */}
      <section className="mt-10">
        <Card>
          <CardBody className="!p-2">
            <div className="flex gap-2 flex-wrap justify-between px-4 !mt-4">
              <Typography variant="h3" color="blue-gray">
                App Visitor Statistics
              </Typography>
              <div className="flex items-center gap-6">
                <div className="flex items-center gap-1">
                  <span className="h-2 w-2 bg-blue-500 rounded-full"></span>
                  <Typography variant="small" className="font-normal text-gray-600">
                    2024
                  </Typography>
                </div>
              </div>
            </div>
            <Suspense fallback={<div>Loading chart...</div>}>
            <AreaChart
              colors={["#4CAF50"]}
              options={{
                xaxis: {
                  categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                },
              }}
              series={[
                {
                  name: "Visitors",
                  data: [1200, 1500, 1700, 2000, 1200, 1500, 1700],
                },
              ]}
            />
            </Suspense>
          </CardBody>
          <CardFooter className="flex gap-6 flex-wrap items-center justify-between">
            <div>
              <Typography variant="h6" color="blue-gray">
                Weekly Visitor Statistics
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-gray-600 mt-1"
              >
                A comparison of visitors over the last month.
              </Typography>
            </div>
            <Button variant="outlined">View Report</Button>
          </CardFooter>
        </Card>
      </section>






      <section className="mt-10">
        <Card>
          <CardBody className="!p-2">
            <div className="flex gap-2 flex-wrap justify-between px-4 !mt-4">
              <Typography variant="h5" color="blue-gray">
                Uwiano wa wanachama na Watumiaji wa App (2024)
              </Typography>
              {/* <div className="flex items-center gap-6">
                <div className="flex items-center gap-1">
                  <span className="h-2 w-2 bg-blue-500 rounded-full"></span>
                  <Typography variant="small" className="font-normal text-gray-600">
                    2024
                  </Typography>
                </div>
              </div> */}
               <div className="flex justify-center sm:justify-end items-center gap-x-4 mb-3 sm:mb-6">
                <div className="inline-flex items-center">
                    <span className="size-2.5 inline-block bg-blue-600 rounded-sm me-2"></span>
                    <span className="text-[13px] text-gray-600 dark:text-neutral-400">Wanachana</span>
                </div>
                <div className="inline-flex items-center">
                    <span className="size-2.5 inline-block bg-purple-600 rounded-sm me-2"></span>
                    <span className="text-[13px] text-gray-600 dark:text-neutral-400">Watumiaji</span>
                </div>
            </div>
            </div>
           
            {/* End Legend Indicator */}

            {/* Apex Chart */}
            <div id="hs-curved-area-charts">
                <Chart options={chartOptions} series={chartOptions.series} type="area" height={400} />
            </div>
            </CardBody>
          <CardFooter className="flex gap-6 flex-wrap items-center justify-between">
            <div>
              <Typography variant="h6" color="blue-gray">
                Uwiano wa mwaka mzima
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-gray-600 mt-1"
              >
                Uwino huu ni ulinganishi wa kila Mwezi katika Mwaka.
              </Typography>
            </div>
            <Button variant="outlined">View Report</Button>
          </CardFooter>
        </Card>
      </section>
      
    </div>
  );
}

export default DashboardPage;