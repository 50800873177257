// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import './index.css';
// import { ThemeProvider } from "@material-tailwind/react";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <ThemeProvider>
//   <App />
// </ThemeProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();





// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './index.css'; // Ensure this file is correctly located
import { ThemeProvider } from "@material-tailwind/react";
import './App.css'
import { PrimeReactProvider } from 'primereact/api';





ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider>
    <PrimeReactProvider value={{ unstyled: true }}>
      <App />
      </PrimeReactProvider>
    </ThemeProvider>
  </React.StrictMode>
);
