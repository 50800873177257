
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/24/outline';
import { timeDifference } from '../utils/helperFunctions';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import AuthService from '../services/auth/auth_service';

const Sponsers = () => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null); // Single image
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const[name, setName] = useState('');
    const [refreshing, setRefreshing] = useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setImage(null); // Clear image when closing modal
    };

    // Handle drag and drop of images (allow only one)
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: (acceptedFiles) => {
            // Replace the existing image with the new one
            setImage(
                Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
            );
        },
        multiple: false, // Ensure only one image can be selected
    });

    // Remove image from preview
    const handleRemoveImage = () => {
        setImage(null);
    };

    useEffect(() => {
        fetchData();
    }, []);

    

    const fetchData = async (page = 1) => {
        setLoading(true);
        // try {
        //     const response = await fetch(`http://localhost:8000/v1/sponsers/?page=${page}`, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //         },
        //     });
    
        //     if (!response.ok) {
        //         throw new Error(`Error fetching news: ${response.statusText}`);
        //     }
    
        //     const data = await response.json();
            
        // if (page === 1) {
        //     setPosts(data.results);
        // } else {
        //     setPosts(prevData => [...prevData, ...data.results]);
        // }
    
        //     setNextPage(data.next ? page + 1 : null); 
    
        // } catch (error) {
        //     console.error("Error fetching news:", error);
        //     // You can set some error state to notify users
        // } finally {
        //     setLoading(false);
        // }


        AuthService.getSponsers(page).then((response) => {
            if (page === 1) {
                setPosts(response.data.results);
            } else {
                setPosts(prevData => [...prevData, ...response.data.results]);
            }
    
            setNextPage(response.data.next ? page + 1 : null); 
    
        }).catch((error) => {
            console.error("Error fetching news:", error);
            // You can set some error state to notify users
        }).finally(() => {
            setLoading(false);
        });
    };







    // Handle image upload submission
    const handleSubmit = async () => {
        if (!image) return;  // Ensure there is at least one image

        const formData = new FormData();
        formData.append('image', image);  // Only send the single image

        formData.append('name', name);

        try {
            // const config = {
            //     headers: {
            //         'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
            //         'Content-Type': 'multipart/form-data',
            //     },
            // };
            // await axios.post('http://localhost:8000/v1/sponsers/', formData, config);
            // setImage(null);
            // setName('');
            // handleRefresh();
            // handleClose();

            AuthService.setSponsers(formData).then((response) => {
                try {
                    if (response.status === 201) {
                        handleRefresh();
                        setImage(null);
                        setName('');
                        handleClose();
                    } else {
                        console.error("Error submitting image:", response.statusText);
                    }
                } catch (error) {
                    console.error("Error submitting image:", error);
                }
            })
        } catch (error) {
            console.error("Error submitting image:", error);
        }
    };

    // Triggered when user reaches end of the list
    



    const loadMoreData = () => {
        if (nextPage && !loading) {
            fetchData(nextPage); // Fetch the next page of news
        }
    };
    
    const handleRefresh = () => {
        setRefreshing(true);
        setNextPage(1); // Reset to the first page on refresh
        setPosts([]); // Clear the previous data
        fetchData(1).finally(() => setRefreshing(false)); // Fetch fresh data on refresh
    };
    


    return (
        <div className="w-full h-full p-4 bg-gray-100">
            <div className="bg-white py-6 sm:py-8 lg:py-12">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <div className="mb-6 flex items-end justify-between gap-4">
                        <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">Sponsers</h2>
                        <button
                            onClick={handleOpen}
                            className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                        >
                            Add more
                        </button>
                    </div>

                    <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
                        {/* News cards */}
                        {posts.map((post, index) => (
                            <div key={index}>
                                <a href="#" className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                                    <img src={post.compressed_image} loading="lazy" alt="Sponser" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                </a>
                                <div className="flex flex-col">
                                    <span className="text-gray-500">{timeDifference(post.created_at)}</span>
                                    <a href="#" className="text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl">{post.created_by_full_name}</a>
                                </div>
                            </div>
                        ))}
                    </div>

                    {nextPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={loadMoreData}
                                className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                            >
                                Load more
                            </button>
                        </div>
                    )}

                    {/* Modal */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Add Sponser</DialogTitle>
                        <DialogContent>
                            <div className="my-4">
                                <div className="mb-4">
                                    {/* name */}
                                    <TextField fullWidth id="outlined-basic" label="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} variant="outlined" />

                                </div>
                                <div
                                    {...getRootProps({
                                        className:
                                            'border-dashed border-2 p-4 text-center bg-gray-50',
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <div className="flex flex-col items-center justify-center py-10 text-center">
                                        <svg className="w-6 h-6 mr-1 text-current-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <p className="m-0">Drag your file here or click to select one image.</p>
                                    </div>
                                </div>
                                {image && (
                                    <div className="mt-4 relative">
                                        <img
                                            src={image.preview}
                                            alt={image.name}
                                            className="h-32 w-32 object-cover"
                                        />
                                        <button
                                            onClick={handleRemoveImage}
                                            className="absolute top-0 right-0 p-1 text-red-500"
                                        >
                                            ✖
                                        </button>
                                    </div>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Sponsers;

