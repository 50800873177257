

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  IconButton,
  Option,
  Textarea,
  Label
} from '@material-tailwind/react';
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useCountries } from "use-react-countries"; // Import use-react-countries
import Select from 'react-select'; // Import react-select

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ShieldCheckIcon, CubeTransparentIcon, FireIcon } from '@heroicons/react/24/solid'; // Import Heroicons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faShieldAlt, faUser, faFire } from '@fortawesome/free-solid-svg-icons'; // Import Font Awesome icons
import axios from 'axios';
import AuthService from '../services/auth/auth_service';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: 'transparent', // Makes the border transparent
    boxShadow: state.isFocused ? '0 0 0 2px rgba(75, 85, 99, 0.3)' : 'none', // Adds focus ring on focus
    '&:hover': {
      borderColor: 'transparent', // Keeps border transparent on hover
    },
    backgroundColor: '#f3f4f6', // Custom background color
    padding: '0.25rem', // Adds padding to make it similar to regular inputs
    height: '3.8rem', // Custom height
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Ensures the dropdown appears above other elements
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6b7280', // Custom placeholder color
  }),
};


const PlayerPage = () => {
  const [selectedType, setSelectedType] = useState('Men');
  const [open, setOpen] = useState(false);
  const [playerData, setPlayerData] = useState({
    name: '',
    location: '',
    type: 'Men',
    jerseyNumber: '',
    age: '',
    description: '',
    image: null,
  });

  const [playerType, setPlayerType] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [dob, setDob] = useState(null);





  const [selectedTab, setSelectedTab] = useState('Men');
  const [refreshing, setRefreshing] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const fetchPlayers = async () => {
    setIsLoading(true)
    setRefreshing(true);
    try {
      // const response = await axios.get('http://localhost:8000/api/players/grouped/', {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
      //   },
      // });
      // setData(response.data);

      // setTimeout(() => {
      //   setIsLoading(false)

      // }, 5000)


      AuthService.getPlayers().then((response) => {
        setData(response.data);
        setIsLoading(false)
      }).catch((error) => {
        console.error(error);
      });

    } catch (error) {
      console.error(error);
    } finally {
      setRefreshing(false);
      // setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);




  const data1 = [
    {
      id: '1',
      title: 'Goal Keepers',
      players: [
        { id: '1', name: 'Max Nzengelddi', location: 'Congo', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },
        { id: '32', name: 'Mudathir Yahya', location: 'Tanzania', image: 'https://pbs.twimg.com/media/GWKPqlTXMAE7fOQ?format=jpg&name=large', type: 'Men' },
        { id: '11', name: 'Misher Abutwalib', location: 'Congo', image: 'https://pbs.twimg.com/media/GTgVPfeXYAAC8Kv?format=jpg&name=large', type: 'Women' },
        { id: '12', name: 'Max Nzengeli', location: 'Congo', image: 'https://pbs.twimg.com/media/GTgVPfeXYAAC8Kv?format=jpg&name=large', type: 'Academy' },
        { id: '13', name: 'Mudathir Yahya', location: 'Tanzania', image: 'https://pbs.twimg.com/media/GTgVPfeXYAAC8Kv?format=jpg&name=large', type: 'Academy' },
        { id: '2', name: 'Clotous Chama', location: 'Zambia', image: 'https://pbs.twimg.com/media/GWKPqleWQAAq8rY?format=jpg&name=large', type: 'Men' },
        { id: '21', name: 'Azizi ki', location: 'Bukinafaso', image: 'https://pbs.twimg.com/media/GV6Z5qoXQAAnQLk?format=jpg&name=large', type: 'Women' },
        { id: '132', name: 'Max Nzengelddi', location: 'Congo', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },





      ],
    },
    {
      id: '2',
      title: 'Defenders',
      players: [
        { id: '3', name: 'Djigui Diarra', location: 'Mali', image: 'https://pbs.twimg.com/media/GWKRHWwWIAAddHj?format=jpg&name=large', type: 'Men' },
        { id: '4', name: 'Dickson Job', location: 'Tanzania', image: 'https://example.com/image4.jpg', type: 'Academy' },
        { id: '31', name: 'Duke Abuya', location: 'Kenya', image: 'https://pbs.twimg.com/media/GWKPqleWQAAq8rY?format=jpg&name=large', type: 'Women' },
        { id: '41', name: 'Prince Dube', location: 'Zimbabwe', image: 'https://pbs.twimg.com/media/GWKRHWtWEAAX5P9?format=jpg&name=large', type: 'Men' },
        { id: '133', name: 'Max Nzengelddi', location: 'Congo', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },

        { id: '142', name: 'Max Nzengelddi', location: 'Congo', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },

        { id: '112', name: 'Max Nzengelddi', location: 'Congo', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },
      ],
    },
    {
      id: '3',
      title: 'Attackers',
      players: [
        { id: '5', name: 'Player 5', location: 'Country E', image: 'https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large', type: 'Men' },
        { id: '6', name: 'Player 6', location: 'Country F', image: 'https://pbs.twimg.com/media/GWKS48RWEAA8-x1?format=jpg&name=large', type: 'Women' },
        { id: '17', name: 'Player 5', location: 'Country E', image: 'https://example.com/image5.jpg', type: 'Academy' },
        { id: '64', name: 'Player 6', location: 'Country F', image: 'https://pbs.twimg.com/media/GWKPqleWQAAq8rY?format=jpg&name=large', type: 'Men' },
        { id: '56', name: 'Player 5', location: 'Country E', image: 'https://example.com/image5.jpg', type: 'Academy' },
      ],
    },
  ];
  const handleOpen = () => setOpen(!open);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlayerData({ ...playerData, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleAddPlayer = () => {
    // Handle adding player logic here (e.g., update state, make API call)
    console.log('Player added:', playerData);
    handleOpen(); // Close the modal
  };






  const { countries } = useCountries(); // Get the list of countries
  const [selectedCountry, setSelectedCountry] = useState(null);

  // Transform countries into a format compatible with react-select
  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: (
      <div className="flex items-center">
        <img
          src={country.flags.svg} // Use the flag image from the country data
          alt={country.name}
          style={{ width: '20px', marginRight: '10px' }} // Styling for flag
        />
        {country.name}
      </div>
    ),
  }));

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    console.log('Selected Country:', selectedOption);
  };



  const [position, setPosition] = useState(null); // Add this state for category

  // const categoryOptions = [
  //   { value: 'Goalkeeper', label: 'Goal keeper' },
  //   { value: 'Defender', label: 'Defender' },
  //   { value: 'Midfielder', label: 'Midfielder' },
  //   { value: 'Forward', label: 'Forward' },

  // ];
  const categoryOptions = [
    {
      value: 'Goalkeeper',
      label: (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFutbol} color='#FF0000' className="mr-2" /> Goalkeeper
        </div>
      ),
    },
    {
      value: 'Defender',
      label: (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faShieldAlt} color='green' className="mr-2" /> Defender
        </div>
      ),
    },
    {
      value: 'Midfielder',
      label: (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} color='blue' className="mr-2" /> Midfielder
        </div>
      ),
    },
    {
      value: 'Forward',
      label: (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFire} color='orange' className="mr-2" /> Forward
        </div>
      ),
    },
  ];


  const handlePositionChange = (selectedOption) => {
    setPosition(selectedOption); // Update the selected category state

    console.log('val', selectedOption.value);
  };















  const [activeTab, setActiveTab] = useState("Men");

  const tabStyle = "cursor-pointer py-2 px-4 text-gray-600 hover:text-gray-900";
  const activeTabStyle = "border-b-2 border-orange-500 text-orange-500";
  const tabPanelStyle = "p-4";

  const renderTabs = () => (
    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
      {/* <div className="flex flex-wrap space-x-2 mb-4 md:mb-0">
        {['Men', 'Women', 'Academy'].map((type) => (
          <button
            key={type}
            onClick={() => setSelectedType(type)}
            className={`py-2 px-4 rounded-lg font-semibold text-lg transition-all duration-300 ease-in-out ${
              selectedType === type
                ? 'bg-blue-500 text-white shadow-lg'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
            }`}
          >
            {type}
          </button>
        ))}
      </div> */}

      <div className="flex justify-between items-center border-b mb-4">
        <h1 className="flex text-2xl font-semibold">
          {['Men', 'Women', 'Academy'].map((category) => (
            <div
              key={category}
              className={`cursor-pointer py-2 px-4 ${activeTab === category ? activeTabStyle : tabStyle}`}
              onClick={() => { setActiveTab(category); setSelectedType(category); }}
            >
              {category}
            </div>
          ))}
        </h1>
        {/* <Button color="green" onClick={handleOpen}>
                    Add News
                </Button> */}
      </div>

      <button
        onClick={handleAddPlayer}
        className="inline-flex items-center rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"

      >
        <PlusIcon className="h-6 w-6 mr-2" />
        ADD PLAYER
      </button>

    </div>
  );

  const renderPlayers = () => {
    return data.map((section) => {
      const filteredPlayers = section.players.filter(
        (player) => player.type === selectedType
      );
      if (filteredPlayers.length > 0) {
        return (
          <div key={section.id} className="mb-8">
            <Typography variant="h4" className="mb-4 text-orange-900">
              {section.title}
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-20">
              {filteredPlayers.map((player) => (
                // <Card key={player.id} className="w-96">
                //   <CardHeader floated={false} className="h-80">
                //     <img
                //       src={player.image}
                //       alt="profile-picture"
                //       className="object-cover "
                //     />
                //   </CardHeader>
                //   <CardBody className="text-center">
                //     <Typography variant="h4" color="blue-gray" className="mb-2">
                //       {player.name}
                //     </Typography>
                //     <Typography
                //       color="blue-gray"
                //       className="font-medium text-red-800"
                //       textGradient
                //     >
                //       {player.location}
                //     </Typography>
                //   </CardBody>
                // </Card>


                // <a href="#" class="group relative flex h-48 flex-col overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-64 xl:h-96">
                //   <img src={player.image} loading="lazy" alt="Photo by Martin Sanchez" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                //   <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent"></div>

                //   <div class="relative mt-auto text-center p-4">
                //     {/* <span class="block text-sm text-gray-200">January 27, 2021</span> */}
                //     <h2 class="mb-2 text-xl font-semibold text-white transition duration-100">{player.name}</h2>

                //     <span class="font-semibold text-indigo-300">{player.location}</span>
                //   </div>
                // </a>


                <div>
                  <a href="#" class="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg">
                    <img src={player.image} loading="lazy" alt="Photo by engin akyurt" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                    <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent"></div>

                    {/* <div class="relative mt-auto text-center p-4">
                    <span class="block text-sm text-gray-200">January 27, 2021</span>
                    <h2 class="mb-2 text-xl font-semibold text-white transition duration-100">{player.name}</h2>

                    <span class="font-semibold text-indigo-300">{player.location}</span>
                  </div> */}
                    <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent"></div>

                    <div class="relative mt-auto text-center w-full">
                      {/* <span class="block text-sm text-gray-200">January 27, 2021</span> */}
                      <h2 class="mb-2 text-xl font-semibold text-white transition duration-100">{player.name}</h2>

                      <span class="font-semibold text-indigo-300">{player.location}</span>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        );
      }
      return null;
    });
  };




  return (
    <div className="w-full h-full p-4 bg-gray-100">

      {open ? (



        <div className=" bg-gray-100 min-h-screen">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-gray-800">Add Player</h1>

            <button
              onClick={() => setOpen(false)}
              className="flex items-center font-bold py-2 px-4 bg-orange-500 text-white rounded-lg hover:bg-green-600  transition-all duration-300 ease-in-out"
            >
              <ArrowLeftIcon className="h-6 w-6 mr-2" />
              GO BACK
            </button>
          </div>
          {/* <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 shadow-lg rounded-lg"> */}
          <form className=" mx-auto bg-white p-6 shadow-lg rounded-lg">





            <div class="bg-white  ">
              <div class="mx-auto max-w-screen-2xl px-4 md:px-8">




                <div className="flex justify-center mb-8">
                  <div className="relative">
                    <div className="w-40 h-40 rounded-full overflow-hidden bg-gray-100 border-2 border-gray-300">
                      {imagePreview ? (
                        <img src={imagePreview} alt="Player Preview" className="object-cover w-full h-full" />
                      ) : (
                        <div className="flex items-center justify-center h-full text-gray-400">
                          <span className="text-sm">No Image</span>
                        </div>
                      )}
                    </div>
                    <input
                      type="file"
                      id="playerImage"
                      name="playerImage"
                      accept="image/*"
                      className="absolute inset-0 opacity-0  cursor-pointer"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>

                <form class=" grid  gap-4 ">



                  <div>
                    <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Full Name*</label>
                    <input name="title" class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                  </div>

                  <div>
                    <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Common Name*</label>
                    <input name="title" class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                  </div>

                  <div>
                    <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Nationality*</label>
                    <div className="mb-4 ">
                      {/* <select
                        placeholder={"Select Video Type"}
                        name="videoType"
                        className="w-full border-gray-400 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                        required
                      >
                        <option value=""></option>
                        <option value="Tutorial">Tanzania</option>
                        <option value="Entertainment">Zambia</option>
                        <option value="Documentary">Kenya</option>
                        <option value="Sports">Uganda</option>
                      </select> */}
                      <Select
                        styles={customStyles}
                        className="w-full"
                        options={countryOptions} // Provide country options to the Select component
                        value={selectedCountry} // Set the selected country value
                        onChange={handleCountryChange} // Handle selection changes
                        placeholder="Search and select a country..."
                        isSearchable // Enable search functionality
                      />
                    </div>
                  </div>

                  <div>
                    <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Position*</label>
                    <div className="mb-4 ">
                      {/* <select
                        placeholder={"Select Video Type"}
                        name="videoType"
                        className="w-full border-gray-400 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                        required
                      >
                        <option value=""></option>
                        <option value="Goalkeeper">Goal Keeper</option>
                        <option value="Defender">Defender</option>
                        <option value="Midfielder">Midfielder</option>
                        <option value="Sports">Forward</option>
                      </select> */}
                      <Select
                        value={position}
                        onChange={handlePositionChange}
                        styles={customStyles}
                        options={categoryOptions} // Pass options to the select component
                        isSearchable // Enable search functionality
                        placeholder="Select a position..."
                        className="w-full"
                      />
                    </div>
                  </div>


                  <div>
                    <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Jersey Number*</label>
                    <input name="title" type="number" class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                  </div>


                  <div>
                    <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Player Type*</label>
                    <div className="mb-4 ">
                      <select
                        placeholder={"Select Video Type"}
                        name="videoType"
                        className="w-full border-gray-400 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                        required
                      >
                        <option value=""></option>
                        <option value="Tutorial">Men</option>
                        <option value="Entertainment">Women</option>
                        <option value="Documentary">Academy</option>
                      </select>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Date of Birth*</label>
                    <div className="mb-4 ">
                      <DatePicker
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        styles={customStyles}
                        className="w-12 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                        placeholderText="Select a date"
                      />
                    </div>
                  </div>

                  <div>
                    <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Height*</label>
                    <input name="heigh" type="number" class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                  </div>


                  <div class="sm:col-span-2">
                    <label for="message" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Description*</label>
                    <textarea name="description"
                      class="h-48 w-full rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"></textarea>
                  </div>



                  <div class="flex items-center justify-between sm:col-span-2">
                    <button class="inline-block rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Send</button>

                    <span class="text-sm text-gray-500">*Required</span>
                  </div>


                </form>
              </div>
            </div>

















            {/* <div className="text-right">
        <Button type="submit" color="blue">
            Add Video
        </Button>
    </div> */}
          </form>
        </div>





      ) : (
        <>
          {renderTabs()}
          {renderPlayers()}
        </>
      )}

    </div>
  );
};

export default PlayerPage;

