// import React, { useState } from 'react';
// import { Button, Input, Card, Typography } from '@material-tailwind/react';
// import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'; // Import eye icons
// import shareVideo from '../assets/share.mp4';
// import { useNavigate } from "react-router-dom";


// const Login = () => {
//   const navigate = useNavigate();

//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);

//   const togglePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//   };



//   const handleLogin = () => {
//     // Perform login logic here (e.g., authentication)

//     // Set the session value in localStorage
//     sessionStorage.setItem('isLogin', 'true');

//     // After successful login, redirect to the dashboard
//     navigate('/yanga/dashboard');
//   };

//   return (
//     <div className="flex justify-start items-center flex-col h-screen">
//       <div className="relative w-full h-full">
//         <video
//           src={shareVideo}
//           type="video/mp4"
//           loop
//           controls={false}
//           muted
//           autoPlay
//           className="w-full h-full blur-sm object-cover"
//         />

//         <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
//           <Card className="w-[28rem] p-10 shadow-2xl bg-white/80 rounded-lg flex flex-col items-center">
//             <img
//               src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d1/Young_Africans_SC_%28logo%29.png/250px-Young_Africans_SC_%28logo%29.png"
//               width="100px"
//               alt="Logo"
//               className="mb-6"
//             />

//             <Typography variant="h4" color="yellow" className="mb-6 text-center">
//               Sign In
//             </Typography>

//             <div className="space-y-4 w-full">
//               <Input
//                 label="Email"
//                 type="email"
//                 className="text-gray-600 border-green-800"
//                 color="gray"
//                 labelProps={{
//                     className: "text-gray-600", // Adjust the label color to be lighter in dark mode
//                   }}
//               />
//               <div className="relative">
//                 <Input
//                   label="Password"
//                   type={isPasswordVisible ? 'text' : 'password'}
//                   className="text-gray-600 border-green-800"
//                   color="gray"
//                   labelProps={{
//                     className: "text-gray-600", // Adjust the label color to be lighter in dark mode
//                   }}
//                 />
//                 <button
//                   type="button"
//                   onClick={togglePasswordVisibility}
//                   className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                 >
//                   {isPasswordVisible ? (
//                     <EyeSlashIcon className="h-5 w-5" />
//                   ) : (
//                     <EyeIcon className="h-5 w-5" />
//                   )}
//                 </button>
//               </div>
//             </div>

//             <Button onClick={handleLogin} fullWidth className="mt-6">Sign In</Button>
//           </Card>




//         </div>
//       </div>
//     </div>

//     // <section className="login_section d-flex align-items-center justify-content-centervflex justify-start items-center flex-col h-screen">

//     //   <div className="relative w-full h-full">
//     //     <video
//     //       src={shareVideo}
//     //       type="video/mp4"
//     //       loop
//     //       controls={false}
//     //       muted
//     //       autoPlay
//     //       className="w-full h-full blur-sm object-cover"
//     //     />
//     //      <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
//     //     <div className="login_Box shadow rounded-1">
//     //       <div className="logo text-center mb-4">
//     //         <center><img src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d1/Young_Africans_SC_%28logo%29.png/250px-Young_Africans_SC_%28logo%29.png" alt="logo" width="100px" height={"100px"} className="img-fluid" /></center>
//     //         <h1 className="h1 pt-2 mt-1">Welcome Back</h1>
//     //         <p style={{color:"white"}} className=" text-lg">Please enter your details to sign in</p>
//     //       </div>
//     //       {/* <div className="d-flex justify-content-between mb-4">
//     //         <div className="google d-flex align-items-center border border-light border-2 w-50 me-2 py-2 rounded justify-content-center">
//     //           <img src="./images/google.png" width="25px" alt="google" />
//     //           <p className="ms-2">Google </p>
//     //         </div>
//     //         <div className="fb d-flex  align-items-center border border-light border-2 w-50 ms-2 py-2 rounded justify-content-center">
//     //           <img src="./images/facebook.png" width="25px" alt="facebook" />
//     //           <p className="ms-2">Facebook </p>
//     //         </div>
//     //       </div> */}
//     //       <div className=" mb-4 d-flex">
//     //         <div className="line" />
//     //         <small style={{color:"white"}} className="or text-center px-2">for Valid users Only</small>
//     //         <div className="line" />
//     //       </div>
//     //       <form onSubmit={handleLogin} className="needs-validation" noValidate>
//     //         <div className="login_form">
//     //           <div className="form-floating mb-4">
//     //             <input type="email" className="form-control" id="email" placeholder="name@example.com" required />
//     //             <label htmlFor="email " className="small_text">
//     //               Email address</label>
//     //             <div className="invalid-feedback">
//     //               Please provide a valid email.
//     //             </div>
//     //           </div>
//     //           <div className="form-floating position-relative">
//     //             <input type="password" className="form-control" id="password" placeholder="Password" required />
//     //             <label htmlFor="password" className="small_text">Password</label>
//     //             <i id="eye-icon" className="fa fa-eye  position-absolute" aria-hidden="true" />
//     //             <div className="invalid-feedback">
//     //               Please provide a password.
//     //             </div>
//     //           </div>
//     //         </div>
//     //         <div className="text-end mb-4 mt-2">
//     //           <span style={{color:"white", fontWeight:"bold"}} className="text-end small_text link-primary">Forgot your password?</span>
//     //         </div>
//     //         <button className="btn btn-warning w-100" type="submit">Log in</button>
//     //         <p className="text-center mt-5 small_text">
//     //           Don't have an account ? <a href="#">Create An Account</a>
//     //         </p>
//     //       </form>
//     //     </div>
//     //     </div>
//     //   </div>
//     // </section>
//   );
// };

// export default Login;
















// // Login.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Input, Card, Typography } from '@material-tailwind/react';
// import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
// import { useNavigate } from "react-router-dom";
// import AuthService from '../services/auth/auth_service';
// import shareVideo from '../assets/share.mp4';
// import { InputOtp } from 'primereact/inputotp';




// const Login = () => {
//   const [username, setUsername] = useState('ZachariaMwalwama');
//   const [password, setPassword] = useState('123456');
//   const [otp, setOtp] = useState();
//   const [otpSent, setOtpSent] = useState(false);
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [stylesLoaded, setStylesLoaded] = useState(false); // State to track styles loaded


//   const navigate = useNavigate();


//   const styleLinks = []; // Array to keep track of dynamically loaded styles

//   useEffect(() => {
//     // Dynamically load PrimeReact CSS
//     const loadPrimeReactStyles = async () => {
//       const theme = await import('primereact/resources/themes/lara-light-cyan/theme.css');
//       const primereact = await import('primereact/resources/primereact.min.css');
//       const primeicons = await import('primeicons/primeicons.css');
//       const primeflex = await import('primeflex/primeflex.css');
//       styleLinks.push(theme.default, primereact.default, primeicons.default, primeflex.default);
//       setStylesLoaded(true);
//     };
//     loadPrimeReactStyles();

//     return () => {
//       // Clean up styles if they were loaded
//       if (stylesLoaded) {
//         styleLinks.forEach(link => {
//           const styleElement = document.querySelector(`link[href="${link}"]`);
//           if (styleElement) {
//             styleElement.parentNode.removeChild(styleElement);
//           }
//         });
//       }
//     };
//   }, [stylesLoaded]);

//   const togglePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//   };

//   const handleLogin = async () => {
//     // try {
//     //   const response = await axios.post('http://localhost:8000/api/auth/web/login/', { username, password });
//     //   if (response.status === 200) {
//     //     setOtpSent(true);
//     //   }
//     // } catch (error) {
//     //   console.error('Login error:', error.response.data);
//     // }

//     const payload = {
//       username: username,
//       password: password
//     };

//     console.log(payload);


//     AuthService.login(payload).then((response) => {
//       try {
//         if (response.status === 200) {
//           setOtpSent(true);
//         } else {
//           console.error('Login error:', response.data);
//         }
//       } catch (error) {
//         console.error('Login error:', error.response.data);
//       }
//     })


//   };

//   const handleOtpVerify = async () => {
//     const payload = {
//       username: username,
//       otp: otp
//     };

//     console.log(payload);
//     AuthService.verifyOTP(payload).then((response) => {
//       try {
//         if (response.status === 200) {
//           console.log('OTP verification successful:', response.data);
//           sessionStorage.setItem('token', response.data.access);
//           // Redirect to the dashboard or another page
//           sessionStorage.setItem('isLogin', 'true');



//           styleLinks.forEach(link => {
//             const styleElement = document.querySelector(`link[href="${link}"]`);
//             if (styleElement) {
//               styleElement.parentNode.removeChild(styleElement);
//             }
//           });
//           navigate('/yanga/dashboard');

//           window.location.reload();

//         }
//       } catch (error) {
//         console.error('OTP verification error:', error.response.data);
//       }
//     })


//   };



//   const customInput = ({ events, props }) => {
//     return <><input {...events} {...props} type="text" className="custom-otp-input-sample" />
//       {props.id === 2 && <div className="px-3 justify-center items-center flex">
//         <i className="pi pi-minus" />
//       </div>}
//     </>
//   };


//   return (
//     <div className="flex justify-center items-center h-screen">
//       <div className="relative w-full h-full">
//         <video
//           src={shareVideo}
//           type="video/mp4"
//           loop
//           controls={false}
//           muted
//           autoPlay
//           className="w-full h-full blur-sm object-cover"
//         />

//         <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
//           {/* <Card className="w-[28rem] p-10 shadow-2xl bg-white"> */}
//           <Card className="w-[32rem] p-10 shadow-2xl bg-white/70 rounded-lg  ">
//             <center>
//               <img
//                 src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d1/Young_Africans_SC_%28logo%29.png/250px-Young_Africans_SC_%28logo%29.png"
//                 width="100px"
//                 alt="Logo"
//                 className="mb-6 "
//               />
//             </center>


//             <Typography variant="h4" color="yellow" className="mb-6 text-center">
//               Sign In
//             </Typography>

//             {!otpSent ? (
//               <div className="space-y-4">
//                 <Input
//                   label="Username"
//                   type="text"
//                   value={username}
//                   onChange={(e) => setUsername(e.target.value)}
//                   fullWidth
//                   className='bg-white/50 text-green-900 font-bold'
//                 />
//                 <div className="relative">
//                   <Input
//                     label="Password"
//                     type={isPasswordVisible ? 'text' : 'password'}
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className='bg-white/50 text-green-900 font-bold'

//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility}
//                     className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                   >
//                     {isPasswordVisible ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
//                   </button>
//                 </div>
//                 <center><Button onClick={handleLogin}>Login</Button></center>
//               </div>
//             ) : (
//               <div className="space-y-4">


//                 <div className="card flex justify-content-center">
//                   <style scoped>
//                     {`
//                     .custom-otp-input-sample {
//                         width: 48px;
//                         height: 48px;
//                         font-size: 24px;
//                         appearance: none;
//                         text-align: center;
//                         transition: all 0.2s;
//                         border-radius: 0;
//                         border: 1px solid var(--surface-400);
//                         background: transparent;
//                         outline-offset: -2px;
//                         outline-color: transparent;
//                         border-right: 0 none;
//                         transition: outline-color 0.3s;
//                         color: var(--text-color);
//                     }

//                     .custom-otp-input-sample:focus {
//                         outline: 2px solid var(--primary-color);
//                     }

//                     .custom-otp-input-sample:first-child,
//                     .custom-otp-input-sample:nth-child(5) {
//                         border-top-left-radius: 12px;
//                         border-bottom-left-radius: 12px;
//                     }

//                     .custom-otp-input-sample:nth-child(3),
//                     .custom-otp-input-sample:last-child {
//                         border-top-right-radius: 12px;
//                         border-bottom-right-radius: 12px;
//                         border-right-width: 1px;
//                         border-right-style: solid;
//                         border-color: var(--surface-400);
//                     }
//                 `}
//                   </style>
//                   <div className="flex flex-column align-items-center ">
//                     <p className="font-bold text-xl mb-2">Authenticate Your Account</p>
//                     <p className="text-color-secondary block mb-5">Please enter the code sent to your phone.</p>
//                     <InputOtp value={otp} onChange={(e) => setOtp(e.value)} length={6} inputTemplate={customInput} style={{ display: 'flex', gap: 0 }} />
//                     {/* <div className="flex justify-content-between mt-5 align-self-stretch">
//                     <Button label="Resend Code" link className="p-0"></Button>
//                     <Button label="Submit Code"></Button>
//                 </div> */}
//                   </div>
//                 </div>

//                 <center><Button onClick={handleOtpVerify}>Verify</Button></center>
//               </div>
//             )}
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input, Card, Typography, Spinner } from '@material-tailwind/react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useNavigate, Link } from "react-router-dom";
import AuthService from '../services/auth/auth_service';
import shareVideo from '../assets/yanga.mp4';
import { InputOtp } from 'primereact/inputotp';
// import { set } from 'react-datepicker/dist/date_utils';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const styleLinks = []; // Array to keep track of dynamically loaded styles

  useEffect(() => {
    const loadPrimeReactStyles = async () => {
      if (otpSent) { // Check if OTP section is open
        const theme = await import('primereact/resources/themes/lara-light-cyan/theme.css');
        const primereact = await import('primereact/resources/primereact.min.css');
        const primeicons = await import('primeicons/primeicons.css');
        const primeflex = await import('primeflex/primeflex.css');
        styleLinks.push(theme.default, primereact.default, primeicons.default, primeflex.default);
      }
    };

    loadPrimeReactStyles();

    return () => {
      // Clean up styles if they were loaded
      styleLinks.forEach(link => {
        const styleElement = document.querySelector(`link[href="${link}"]`);
        if (styleElement) {
          styleElement.parentNode.removeChild(styleElement);
        }
      });
    };
  }, [otpSent]); // Depend on otpSent

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async () => {
    // Construct the payload from user input
    setIsLoading(true);
    const payload = {
        username: username, // Assuming you have a state for username
        password: password, // Assuming you have a state for password
    };

    console.log("Logging in with:", payload); // Log payload for debugging

    try {
        // Attempt to log in using the AuthService
        const response = await AuthService.login(payload);

        // Check for successful login (status code 200)
        if (response && response.status === 200) {
            console.log("Login successful:", response.data);
            // Handle successful login, e.g., redirect, show a success message, etc.
            setError(null);
            setOtpSent(true); // Assuming this state manages OTP sent status
            setIsLoading(false);
        } else {
            // Handle unexpected responses
            console.error('Unexpected response:', response);
            setIsLoading(false);
        }
    } catch (error) {
        // Check if the error is from Axios and handle accordingly
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error Status:', error.response.status);
            console.error('Error Data:', error.response.data);

            // Handle specific error statuses
            switch (error.response.status) {
                case 401:
                    // console.error('Invalid credentials:', error.response.data.error || 'Unauthorized access.');
                    // alert('Invalid credentials. Please try again.'); // Show an alert for invalid credentials
                    setError('Invalid credentials. Please try again.');
                    break;
                case 400:
                    // console.error('Bad request:', error.response.data.message || 'Invalid input.');
                    // alert('Please check your input.'); // Show an alert for bad requests
                    setError('Please check your input.');
                    break;
                default:
                    // console.error('Login error:', error.response.data.message || 'An error occurred');
                    // alert('An error occurred. Please try again later.'); // Show a generic error message
                    setError('An error occurred. Please try again later.');
                    break;
            }
        } else {
            // Handle unexpected errors, e.g., network issues
            // console.error('Unexpected error:', error.message);
            // alert('An unexpected error occurred. Please check your network connection.'); // Show a network error alert
            setError('An unexpected error occurred. Please check your network connection.');
        }
    } finally {
        // Optionally reset form inputs or loading states
        setIsLoading(false);
    }
};


  // const handleOtpVerify = async () => {
  //   const payload = {
  //     username: username,
  //     otp: otp
  //   };

  //   AuthService.verifyOTP(payload).then((response) => {
  //     if (response.status === 200) {
  //       console.log('OTP verification successful:', response.data);
  //       sessionStorage.setItem('token', response.data.access);
  //       sessionStorage.setItem('isLogin', 'true');
  //       navigate('/yanga/dashboard');
  //       window.location.reload();
  //     }
  //   }).catch((error) => {
  //     console.error('OTP verification error:', error.response.data);
  //   });
  // };



  const handleOtpVerify = async () => {
    setIsLoadingVerify(true);
    const payload = {
        username: username, // Assuming you have a state for username
        otp: otp // Assuming you have a state for OTP
    };

    console.log("Verifying OTP for:", payload); // Log payload for debugging

    try {
        // Attempt to verify the OTP using AuthService
        const response = await AuthService.verifyOTP(payload);

        // Check for successful verification (status code 200)
        if (response && response.status === 200) {
            console.log('OTP verification successful:', response.data);
            sessionStorage.setItem('token', response.data.access);
            sessionStorage.setItem('isLogin', 'true');
            setError(null);
            navigate('/yanga/dashboard');
            window.location.reload();
            setIsLoadingVerify(false);
        } else {
            // Handle unexpected responses
            // console.error('Unexpected response:', response);
            setError('An unexpected error occurred. Please try again later.');
            setIsLoadingVerify(false);
        }
    } catch (error) {
        // Check if the error is from Axios and handle accordingly
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error Status:', error.response.status);
            console.error('Error Data:', error.response.data);

            setIsLoadingVerify(false);

            // Handle specific error statuses
            switch (error.response.status) {
                case 401:
                    // console.error('Unauthorized:', error.response.data.error || 'Invalid OTP or user.');
                    // alert('Invalid OTP. Please try again.'); // Show an alert for invalid OTP
                    setError('Invalid OTP. Please try again.');
                    break;
                case 400:
                    console.error('Bad request:', error.response.data);

                    // Show specific alert messages based on the error message
                    if (error.response.data === 'Invalid or expired OTP.') {
                        // alert('Invalid or expired OTP. Please check your OTP and request a new one.'); // More specific alert for expired OTP
                        setError('Invalid or expired OTP. Please check your OTP and request a new one.');
                    } else {
                        // alert('Please check your input.'); // General alert for other bad requests
                        setError('Please check your input.');
                    }
                    break;
                default:
                    // console.error('Verification error:', error.response.data.message || 'An error occurred');
                    // alert('An error occurred during OTP verification. Please try again later.'); // Show a generic error message
                    setError('An error occurred during OTP verification. Please try again later.');
                    break;
            }
        } else {
            // Handle unexpected errors, e.g., network issues
            console.error('Unexpected error:', error.message);
            alert('An unexpected error occurred. Please check your network connection.'); // Show a network error alert
        }
    } finally {
        // Optionally reset form inputs or loading states
        setIsLoadingVerify(false);
    }
};





  const customInput = ({ events, props }) => {
    return <>
      <input {...events} {...props} type="text" className="custom-otp-input-sample" />
      {props.id === 2 && <div className="px-3 justify-center items-center flex">
        <i className="pi pi-minus" />
      </div>}
    </>
  };

  return (
    <div onContextMenu={(e) => {e.preventDefault(); return false}} className="flex justify-center items-center h-screen">
      <div className="relative w-full h-full">
        {/* <video
          src={shareVideo}
          type="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          className="w-full h-full blur-lg object-cover"
        /> */}
        <video
          src={shareVideo}
          type="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          className="w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

        
        <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
          {/* <Card className="w-[32rem] p-10 shadow-2xl bg-white/70 rounded-lg"> */}
          <Card className="w-full max-w-[32rem] p-10 shadow-2xl bg-white/70 rounded-lg mx-auto">

            <center>
              <img
                src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d1/Young_Africans_SC_%28logo%29.png/250px-Young_Africans_SC_%28logo%29.png"
                width="100px"
                alt="Logo"
                className="mb-4"
              />
            </center>

            {!otpSent ? (
              <>
                <Typography variant="h4" color="gray" className="mb-6 text-center font-bold text-xl ">
                  Sign In
                </Typography>


                <div className="space-y-4">
                  <Input
                    label="Username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    className='bg-white/50 text-green-900 font-bold'
                    style={{ color: 'green', fontWeight: 'bold' }}
                  />
                  <div className="relative">
                    <Input
                      label="Password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='bg-white/50 text-green-900 font-bold'
                      style={{ color: 'green', fontWeight: 'bold' }}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                    >
                      {isPasswordVisible ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                    </button>
                  </div>
                  <center>
                    <Button disabled={isLoading} onClick={handleLogin}>{isLoading ? <Spinner size="sm" />: 'Login'}</Button>
                  </center>
                  <center>
                    {/* <Typography variant="p" color="gray" className="mt-4 text-center">
                      Don't have an account? <Link to="/register">Register</Link>
                    </Typography> */}
                    <Typography variant="p" color="red" className="mt-4 text-center font-bold">
                      {error}
                    </Typography>
                  </center>
                </div>
              </>
            ) : (
              <div className="space-y-4">
                <div className="card flex justify-content-center">
                  <style scoped>
                    {`
                    .custom-otp-input-sample {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        appearance: none;
                        text-align: center;
                        transition: all 0.2s;
                        border-radius: 0;
                        border: 1px solid var(--surface-400);
                        background: transparent;
                        outline-offset: -2px;
                        outline-color: transparent;
                        border-right: 0 none;
                        transition: outline-color 0.3s;
                        color: var(--text-color);
                    }
                    .custom-otp-input-sample:focus {
                        outline: 2px solid var(--primary-color);
                    }
                    .custom-otp-input-sample:first-child,
                    .custom-otp-input-sample:nth-child(5) {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }
                    .custom-otp-input-sample:nth-child(3),
                    .custom-otp-input-sample:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        border-right-width: 1px;
                        border-right-style: solid;
                        border-color: var(--surface-400);
                    }
                    `}
                  </style>
                  <div className="flex flex-column align-items-center">
                    <p className="font-bold text-xl mb-2">Authenticate Your Account</p>
                    <p className="text-color-secondary block mb-5">Please enter the code sent to your Email.</p>
                    <InputOtp
                      value={otp}
                      onChange={(e) => setOtp(e.value)}
                      length={6}
                      inputTemplate={customInput}
                      style={{ display: 'flex', gap: 0 }}
                    />
                  </div>
                </div>

                <center><Button onClick={handleOtpVerify}>{isLoadingVerify ? <Spinner size="sm" />: 'Verify'}</Button></center>
                <center>
                    {/* <Typography variant="p" color="gray" className="mt-4 text-center">
                      Don't have an account? <Link to="/register">Register</Link>
                    </Typography> */}
                    <Typography variant="p" color="red" className="mt-4 text-center font-bold">
                      {error}
                    </Typography>
                  </center>
                
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
