import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isLogin = sessionStorage.getItem('isLogin');

  return isLogin === 'true' ? children : <Navigate to="/" />;
};

export default PrivateRoute;
