import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  HomeIcon,
  UserIcon,
  ChartBarIcon,
  CogIcon,
  BellIcon,
  ShoppingCartIcon,
  CameraIcon,
  CalendarIcon,
  AcademicCapIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/solid';
import AuthService from '../services/auth/auth_service';

// CategoryScreen component
const NewsCategoryScreen = () => {
  const [isAdding, setIsAdding] = useState(false); // State to switch between listing and adding categories
  const [categoryOptions, setCategoryOptions] = useState([]); // State to store category options
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store selected category
 
  const fetchCategories = async () => {
    try {
        // const response = await axios.get('http://localhost:8000/v1/news-categories/', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //     }
        // });

      

        // setCategoryOptions(response.data);

        AuthService.getCategories().then((response) => {
          try {
              if (response.status === 200) {
                  setCategoryOptions(response.data);
              }
          } catch (error) {
              console.error(error);
          }
        })
    } catch (error) {
        console.error(error);
    }
};

  useEffect(() => {
    fetchCategories();
  }, []);



  return (
    <div className="p-4 bg-slate-100">
      {/* Switch between Listing and Adding */}
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-xl font-semibold">
          {isAdding ? 'Add Category' : 'Categories'}
        </h1>
        <button
          onClick={() => setIsAdding(!isAdding)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          {isAdding ? 'Back to List' : 'Add Category'}
        </button>
      </div>

      {/* Display Category List or Add Category Form */}
      {isAdding ? (
        <div>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Category Name
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                placeholder="Enter category name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Category Icon
              </label>
              {/* <select className="w-full px-3 py-2 border border-gray-300 rounded-lg">
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select> */}
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-lg"
            >
              Save Category
            </button>
          </form>
        </div>
      ) : (
        <ul className="space-y-2">
          {categoryOptions.map((category) => (
            <li
              key={category.id}
              className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg"
            >
              <div className="flex items-center">
                {/* <category.icon className="w-6 h-6 mr-3 text-gray-600" /> */}
                <span>{category.name}</span>
              </div>
              <div className="flex">
                <button className="text-yellow-500 mr-2">Edit</button>
                <button className="text-red-500">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewsCategoryScreen;
