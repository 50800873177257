import React, { useState } from "react";
import { PlusIcon, HomeIcon, PlayCircleIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import {
    Drawer,
    Button,
    Typography,
    IconButton,
    Input,
    Textarea,
} from "@material-tailwind/react";


export default function Shop() {

    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(false);
    const  [productItem, setProductItem] = useState({});
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [categories, setCategories] = useState([
        { name: "Home", icon: <HomeIcon className="h-5 w-5" /> },
        { name: "Settings", icon: <PlayCircleIcon className="h-5 w-5" /> },
    ]);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => {
        setOpen(false);
        setShowAddCategory(false); // Reset to category list on close
    };

    const addCategory = (name) => {
        setCategories([...categories, { name, icon: <PlusIcon className="h-5 w-5" /> }]);
        setShowAddCategory(false); // Switch back to category list
    };









    const products = [
        {
            imageUrl: "https://pbs.twimg.com/media/GTgPop_XcAAaX6s?format=jpg&name=large",
            title: "Yanga First Kit",
            price: "35,000",
            originalPrice: "40,000",
            isOnSale: true,
        },
        {
            imageUrl: "https://pbs.twimg.com/media/GTgPop-WYAAkY9J?format=jpg&name=large",
            title: "Yanga Second Kit",
            price: "35,000",
            originalPrice: "40,000",
        },
        {
            imageUrl: "https://pbs.twimg.com/media/GTgRXLzW4AAzDfs?format=jpg&name=large",
            title: "Pacome Kit",
            price: "50,000"
        },
        {
            imageUrl: "https://pbs.twimg.com/media/GTgRXL5XgAAKTXH?format=jpg&name=large",
            title: "Shiny Shoe",
            price: "10,000",
        },
        {
            imageUrl: "https://pbs.twimg.com/media/GTgV--hWAAAwdKP?format=jpg&name=large",
            title: "Spiky Plant",
            price: "3,000",
        },
        {
            imageUrl: "https://pbs.twimg.com/media/GTgV--kXwAAjEM1?format=jpg&name=large",
            title: "Wieldy Film",
            price: "500",
        },
        {
            imageUrl: "https://images.unsplash.com/photo-1579609598065-79f8e5bcfb70?auto=format&q=75&fit=crop&w=600",
            title: "Sturdy Stand",
            price: "5,000",
            originalPrice: "7,000",
            isOnSale: true,
        },
        {
            imageUrl: "https://images.unsplash.com/photo-1619066045029-5c7e8537bd8c?auto=format&q=75&fit=crop&w=600",
            title: "Lazy Bottle",
            price: "30,000",
        },
    ];


    return (
        <React.Fragment>




            {selectedProduct ?
                <div className="w-full h-full p-4 bg-gray-100">
                   

                    <div className="bg-white py-6 sm:py-8 lg:py-12">
                        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                            <ArrowLeftCircleIcon onClick={() => setSelectedProduct(false)} className="h-16 w-16 cursor-pointer" />
                            </div>
                        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
                            <div className="grid gap-8 md:grid-cols-2">
                                {/* images - start */}
                                <div className="grid gap-4 lg:grid-cols-5">
                                    <div className="order-last flex gap-4 lg:order-none lg:flex-col">
                                        <div className="overflow-hidden rounded-lg bg-gray-100">
                                            <img src="https://pbs.twimg.com/media/GTgPop_XcAAaX6s?format=jpg&name=large" loading="lazy" alt="Photo by Himanshu Dewangan" className="h-full w-full object-cover object-center" />
                                        </div>
                                        <div className="overflow-hidden rounded-lg bg-gray-100">
                                            <img src="https://pbs.twimg.com/media/GTgPop-WYAAkY9J?format=jpg&name=large" loading="lazy" alt="Photo by Himanshu Dewangan" className="h-full w-full object-cover object-center" />
                                        </div>
                                        <div className="overflow-hidden rounded-lg bg-gray-100">
                                            <img src="https://pbs.twimg.com/media/GTgPop_XcAAaX6s?format=jpg&name=large" loading="lazy" alt="Photo by Himanshu Dewangan" className="h-full w-full object-cover object-center" />
                                        </div>
                                    </div>
                                    <div className="relative overflow-hidden rounded-lg bg-gray-100 lg:col-span-4">
                                        <img src={productItem.imageUrl} loading="lazy" alt="Photo by Himanshu Dewangan" className="h-full w-full object-cover object-center" />
                                        <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>
                                        <a href="#" className="absolute right-4 top-4 inline-block rounded-lg border bg-white px-3.5 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:text-gray-700 md:text-base">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                {/* images - end */}
                                {/* content - start */}
                                <div className="md:py-8">
                                    {/* name - start */}
                                    <div className="mb-2 md:mb-3">
                                        <span className="mb-0.5 inline-block text-gray-500">Jersy</span>
                                        <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">{productItem.title}</h2>
                                    </div>
                                    {/* name - end */}
                                    {/* rating - start */}
                                    <div className="mb-6 flex items-center gap-3 md:mb-10">
                                        <div className="flex h-7 items-center gap-1 rounded-full bg-indigo-500 px-2 text-white">
                                            <span className="text-sm">4.2</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                        </div>
                                        <span className="text-sm text-gray-500 transition duration-100">56 ratings</span>
                                    </div>
                                    {/* rating - end */}
                                    {/* color - start */}
                                    <div className="mb-4 md:mb-6">
                                        <span className="mb-3 inline-block text-sm font-semibold text-gray-500 md:text-base">Color</span>
                                        <div className="flex flex-wrap gap-2">
                                            <span className="h-8 w-8 rounded-full border bg-green-800 ring-2 ring-gray-800 ring-offset-1 transition duration-100" />

                                        </div>
                                    </div>
                                    {/* color - end */}
                                    {/* size - start */}
                                    <div className="mb-8 md:mb-10">
                                        <span className="mb-3 inline-block text-sm font-semibold text-gray-500 md:text-base">Size</span>
                                        <div className="flex flex-wrap gap-3">
                                            <button type="button" className="flex h-8 w-12 items-center justify-center rounded-md border bg-white text-center text-sm font-semibold text-gray-800 transition duration-100 hover:bg-gray-100 active:bg-gray-200">XS</button>
                                            <button type="button" className="flex h-8 w-12 items-center justify-center rounded-md border bg-white text-center text-sm font-semibold text-gray-800 transition duration-100 hover:bg-gray-100 active:bg-gray-200">S</button>
                                            <span className="flex h-8 w-12 cursor-default items-center justify-center rounded-md border border-indigo-500 bg-indigo-500 text-center text-sm font-semibold text-white">M</span>
                                            <button type="button" className="flex h-8 w-12 items-center justify-center rounded-md border bg-white text-center text-sm font-semibold text-gray-800 transition duration-100 hover:bg-gray-100 active:bg-gray-200">L</button>
                                            <span className="flex h-8 w-12 cursor-not-allowed items-center justify-center rounded-md border border-transparent bg-white text-center text-sm font-semibold text-gray-400">XL</span>
                                        </div>
                                    </div>
                                    {/* size - end */}
                                    {/* price - start */}
                                    <div className="mb-4">
                                        <div className="flex items-end gap-2">
                                            <span className="text-xl font-bold text-gray-800 md:text-2xl">Tsh {productItem.price}</span>
                                           {productItem.originalPrice &&  <span className="mb-0.5 text-red-500 line-through">Tsh {productItem.originalPrice}</span>}
                                        </div>
                                        <span className="text-sm text-gray-500">incl. VAT plus shipping</span>
                                    </div>
                                    {/* price - end */}
                                    {/* shipping notice - start */}
                                    <div className="mb-6 flex items-center gap-2 text-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                        </svg>
                                        <span className="text-sm">2-4 day shipping</span>
                                    </div>
                                    {/* shipping notice - end */}
                                    {/* buttons - start */}
                                    <div className="flex gap-2.5">
                                        <a href="#" className="inline-block flex-1 rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 sm:flex-none md:text-base">Edit</a>
                                        <a href="#" className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base">Delete</a>
                                    </div>
                                    {/* buttons - end */}
                                </div>
                                {/* content - end */}
                            </div>
                        </div>
                    </div>










                </div>
                : (
                    <>

                        {/*Nav*/}
                        <nav id="header" className="w-full bg-white z-30 top-0 py-1">
                            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-3">
                                {/* <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block">
              <svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </label> */}
                                <input className="hidden" type="checkbox" id="menu-toggle" />
                                <div className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1" id="menu">
                                    {/* <nav>
                <ul className="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
                  <li><a className="inline-block no-underline hover:text-black hover:underline py-2 px-4" href="#">Shop</a></li>
                  <li><a className="inline-block no-underline hover:text-black hover:underline py-2 px-4" href="#">About</a></li>
                </ul>
              </nav> */}
                                </div>
                                <div className="order-1 md:order-2">
                                    <a className="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl " href="#">
                                        <svg className="fill-current text-gray-800 mr-2" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                            <path d="M5,22h14c1.103,0,2-0.897,2-2V9c0-0.553-0.447-1-1-1h-3V7c0-2.757-2.243-5-5-5S7,4.243,7,7v1H4C3.447,8,3,8.447,3,9v11 C3,21.103,3.897,22,5,22z M9,7c0-1.654,1.346-3,3-3s3,1.346,3,3v1H9V7z M5,10h2v2h2v-2h6v2h2v-2h2l0.002,10H5V10z" />
                                        </svg>
                                        Shop
                                    </a>
                                </div>
                                <div className="order-2 md:order-3 flex items-center" id="nav-content">
                                    <a className="inline-block no-underline hover:text-black" href="#">
                                        <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                            <circle fill="none" cx={12} cy={7} r={3} />
                                            <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z" />
                                        </svg>
                                    </a>
                                    <span onClick={openDrawer} className="pl-3 inline-block no-underline hover:text-black" href="#">
                                        <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                            <path d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z" />
                                            <circle cx="10.5" cy="18.5" r="1.5" />
                                            <circle cx="17.5" cy="18.5" r="1.5" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </nav>
                        <div className="carousel relative container mx-auto" style={{ maxWidth: '1600px' }}>
                            <div className="carousel-inner relative overflow-hidden w-full">
                                {/*Slide 1*/}
                                <input className="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden defaultChecked="checked" />
                                <div className="carousel-item absolute opacity-0" style={{ height: '70vh' }}>
                                    <div className=" h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-right" style={{ backgroundImage: 'url("https://pbs.twimg.com/media/GTgPop-WYAAkY9J?format=jpg&name=large")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        <div className="container mx-auto">
                                            <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                                                <p className="text-white font-bold text-2xl my-4">Yanga First Kit For a season</p>
                                                <a className="text-xl text-white font-bold inline-block no-underline border-b border-yellow-600 leading-relaxed hover:text-black hover:border-black" href="#">view product</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="carousel-3" className="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                                <label htmlFor="carousel-2" className="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                                {/*Slide 2*/}
                                <input className="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden />
                                <div className="carousel-item absolute opacity-0 bg-cover bg-right" style={{ height: '70vh' }}>
                                    <div className="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-right" style={{ backgroundImage: 'url("https://pbs.twimg.com/media/GTgV--hWAAAwdKP?format=jpg&name=large")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        <div className="container mx-auto">
                                            <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                                                <p className="text-white font-bold text-2xl my-4">Yanga Second Kit Far Season</p>
                                                <a className="text-xl text-white font-bold inline-block no-underline border-b border-yellow-600 leading-relaxed hover:text-black hover:border-black" href="#">view product</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="carousel-1" className="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                                <label htmlFor="carousel-3" className="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                                {/*Slide 3*/}
                                <input className="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden />
                                <div className="carousel-item absolute opacity-0" style={{ height: '70vh' }}>
                                    <div className="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-bottom" style={{ backgroundImage: 'url("https://pbs.twimg.com/media/GWKS48MWAAAjpVj?format=jpg&name=large")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        <div className="container mx-auto">
                                            <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                                                <p className="text-white font-bold text-2xl my-4">Brown and blue hardbound book</p>
                                                <a className="text-xl inline-block no-underline border-b border-yellow-600 leading-relaxed hover:text-black text-white font-bold hover:border-black" href="#">view product</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="carousel-2" className="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
                                <label htmlFor="carousel-1" className="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
                                {/* Add additional indicators for each slide*/}
                                <ol className="carousel-indicators">
                                    <li className="inline-block mr-3">
                                        <label htmlFor="carousel-1" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900">•</label>
                                    </li>
                                    <li className="inline-block mr-3">
                                        <label htmlFor="carousel-2" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900">•</label>
                                    </li>
                                    <li className="inline-block mr-3">
                                        <label htmlFor="carousel-3" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900">•</label>
                                    </li>
                                </ol>
                            </div>
                        </div>








                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                <div className="mb-6 flex items-end justify-between gap-4">
                                    <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">Selected</h2>
                                    <div className="inline-block cursor-pointer rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base">Add Product</div>
                                </div>
                                <div className="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
                                    {products.map((product, index) => (
                                        <div onClick={() => {setProductItem(product); setSelectedProduct(true)}} key={index}>
                                            <div className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3">
                                                <img src={product.imageUrl} loading="lazy" alt={product.title} className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                                {product.isOnSale && <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>}
                                            </div>
                                            <div>
                                                <a href="#" className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg">{product.title}</a>
                                                <div className="flex items-end gap-2">
                                                    <span className="font-bold text-gray-800 lg:text-lg">Tsh {product.price}</span>
                                                    {product.originalPrice && <span className="mb-0.5 text-red-500 line-through">Tsh {product.originalPrice}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        




{/* 
                        <div className="flex justify-center items-center">
        <div className="2xl:mx-auto 2xl:container lg:px-20 lg:py-16 md:py-12 md:px-6 py-9 px-4 w-96 sm:w-auto">
          <div role="main" className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-semibold leading-9 text-center text-gray-800 dark:text-gray-50">This Week Blogs</h1>
            <p className="text-base leading-normal text-center text-gray-600 dark:text-white mt-4 lg:w-1/2 md:w-10/12 w-11/12">If you're looking for random paragraphs, you've come to the right place. When a random word or a random sentence isn't quite enough</p>
          </div>
          <div className="lg:flex items-stretch md:mt-12 mt-8">
            <div className="lg:w-1/2">
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6">
                <div className="sm:w-1/2 relative">
                  <div>
                    <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                    <div className="absolute bottom-0 left-0 p-6">
                      <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                      <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                      <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                        <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                        <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <img src="https://i.ibb.co/DYxtCJq/img-1.png" className="w-full" alt="chair" />
                </div>
                <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
                  <div>
                    <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                    <div className="absolute bottom-0 left-0 p-6">
                      <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                      <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                      <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                        <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                        <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <img src="https://i.ibb.co/3C5HvxC/img-2.png" className="w-full" alt="wall design" />
                </div>
              </div>
              <div className="relative">
                <div>
                  <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                  <div className="absolute bottom-0 left-0 md:p-10 p-6">
                    <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                    <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                    <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                      <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                      <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
                <img src="https://i.ibb.co/Ms4qyXp/img-3.png" alt="sitting place" className="w-full mt-8 md:mt-6 hidden sm:block" />
                <img className="w-full mt-4 sm:hidden" src="https://i.ibb.co/6XYbN7f/Rectangle-29.png" alt="sitting place" />
              </div>
            </div>
            <div className="lg:w-1/2 xl:ml-8 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
              <div className="relative">
                <div>
                  <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                  <div className="absolute bottom-0 left-0 md:p-10 p-6">
                    <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                    <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                    <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                      <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                      <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
                <img src="https://i.ibb.co/6Wfjf2w/img-4.png" alt="sitting place" className="w-full sm:block hidden" />
                <img className="w-full sm:hidden" src="https://i.ibb.co/dpXStJk/Rectangle-29.png" alt="sitting place" />
              </div>
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6 md:mt-6 mt-4">
                <div className="relative w-full">
                  <div>
                    <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                    <div className="absolute bottom-0 left-0 p-6">
                      <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                      <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                      <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                        <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                        <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <img src="https://i.ibb.co/3yvZBpm/img-5.png" className="w-full" alt="chair" />
                </div>
                <div className="relative w-full sm:mt-0 mt-4">
                  <div>
                    <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p>
                    <div className="absolute bottom-0 left-0 p-6">
                      <h2 className="text-xl font-semibold 5 text-white">The Decorated Ways</h2>
                      <p className="text-base leading-4 text-white mt-2">Dive into minimalism</p>
                      <a href="javascript:void(0)" className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline">
                        <p className="pr-2 text-sm font-medium leading-none">Read More</p>
                        <svg className="fill-stroke" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <img src="https://i.ibb.co/gDdnJb5/img-6.png" className="w-full" alt="wall design" />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div> */}
                    </>
                )}
















        </React.Fragment>
    );
}





