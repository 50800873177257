import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
    Option
} from '@material-tailwind/react';
import axios from 'axios'; // Ensure axios is installed and imported for making API requests

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useDropzone } from "react-dropzone";
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Select from 'react-select'; // Import react-select
import { Autocomplete, TextField, Chip, Avatar } from '@mui/material';
import { truncateText, stripHTML, formatDate, timeDifference, formatLikeCount } from '../utils/helperFunctions'
import AuthService from '../services/auth/auth_service';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent', // Makes the border transparent
        boxShadow: state.isFocused ? '0 0 0 2px rgba(75, 85, 99, 0.3)' : 'none', // Adds focus ring on focus
        '&:hover': {
            borderColor: 'transparent', // Keeps border transparent on hover
        },
        backgroundColor: '#f3f4f6', // Custom background color
        padding: '0.25rem', // Adds padding to make it similar to regular inputs
        height: '3.8rem', // Custom height
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensures the dropdown appears above other elements
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6b7280', // Custom placeholder color
    }),
};



const NewsPage = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [modalOpen, setModalOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const [description, setDescription] = useState("");
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [title, setTitle] = useState(""); // Add this state for title
    const [category, setCategory] = useState(null); // Add this state for category

    const [newsData1, setNewsData1] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [nextPage, setNextPage] = useState(1);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [staff, setStaff] = useState([]);


    




    useEffect(() => {
        fetchNews(); // Initial load
        fetchCategories();
        fetchStaff();
    }, []);


    const fetchNews = async (page = 1) => {
        setLoading(true);
        // try {
        //     const response = await fetch(`http://localhost:8000/api/news/?page=${page}`, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //         },
        //     });
    
        //     if (!response.ok) {
        //         throw new Error(`Error fetching news: ${response.statusText}`);
        //     }
    
        //     const data = await response.json();
        //     console.log("News data:", data);
    
        //     // Append new data to the existing data
            
        // if (page === 1) {
        //     // Replace data when it's the first page (avoid duplication)
        //     setNewsData1(data.results);
        // } else {
        //     // Append new data when loading more pages
        //     setNewsData1(prevData => [...prevData, ...data.results]);
        // }
    
        //     // Handle the next page URL
        //     setNextPage(data.next ? page + 1 : null); 
    
        // } catch (error) {
        //     console.error("Error fetching news:", error);
        //     // You can set some error state to notify users
        // } finally {
        //     setLoading(false);
        // }


        AuthService.getNews(page).then((response) => {
            try {
                if (response.status === 200) {
                    console.log('News data:', response.data);
                    if (page === 1) {
                        // Replace data when it's the first page (avoid duplication)
                        setNewsData1(response.data.results);
                    } else {
                        // Append new data when loading more pages
                        setNewsData1(prevData => [...prevData, ...response.data.results]);
                    }
    
                    // Handle the next page URL
                    setNextPage(response.data.next ? page + 1 : null); 
                }
            } catch (error) {
                console.error("Error fetching news:", error);
                // You can set some error state to notify users
            } finally {
                setLoading(false);
            }
        });
    };


    

    const fetchStaff = async () => {
        // try {
        //     const response = await axios.get('http://localhost:8000/v1/staff/', {
        //         headers: {
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
                    

        //         },
        //     });
        //     const formattedstaff = response.data.map(d => ({
        //         // value: category.id.toString(), // Convert id to string for consistency
        //         // label: category.name

        //         id: d.id,
        //         label: d.full_name,
        //         description: d.staff_type,
        //         icon: d.compressed_avatar

        //     }));

        //     setStaff(formattedstaff);
        // } catch (error) {
        //     console.error('Error fetching staff:', error);
        // }

        AuthService.getStaff().then((response) => {
            try {
                if (response.status === 200) {
                    const formattedstaff = response.data.map(d => ({
                        // value: category.id.toString(), // Convert id to string for consistency
                        // label: category.name
                        id: d.id,
                        label: d.full_name,
                        description: d.staff_type,
                        icon: d.compressed_avatar
                    }));
                    setStaff(formattedstaff);
                }
            } catch (error) {
                console.error('Error fetching staff:', error);
            }
        });
    };


    const handleLoadMore = () => {
        if (nextPage && !loading) {
            fetchNews(nextPage); // Fetch the next page of news
        }
    };
    
    const handleRefresh = () => {
        setRefreshing(true);
        setNextPage(1); // Reset to the first page on refresh
        setNewsData1([]); // Clear the previous data
        fetchNews(1).finally(() => setRefreshing(false)); // Fetch fresh data on refresh
    };
    






    const categoryOptions1 = [
        { value: '1', label: 'Trophy' },
        { value: '2', label: 'Match' },
        { value: '3', label: 'Sponsors' },
        { value: '4', label: 'Training' },
        { value: '5', label: 'Special News' },
        { value: '6', label: 'Special Tag' },
        { value: '7', label: 'Upcoming' },
        // Add more options here as needed
    ];

    const fetchCategories = async () => {
        // try {
        //     const response = await axios.get('http://localhost:8000/v1/news-categories/', {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //         }
        //     });

        //     // Transform API response to match the categoryOptions format
        //     const formattedCategories = response.data.map(category => ({
        //         value: category.id.toString(), // Convert id to string for consistency
        //         label: category.name
        //     }));

        //     setCategoryOptions(formattedCategories);
        // } catch (error) {
        //     console.error(error);
        // }

        AuthService.getCategories().then((response) => {
            try {
                if (response.status === 200) {
                    const formattedCategories = response.data.map(category => ({
                        value: category.id.toString(), // Convert id to string for consistency
                        label: category.name
                    }));
                    setCategoryOptions(formattedCategories);
                }
            } catch (error) {
                console.error(error);
            }
        });
    };



    const handleCategoryChange = (selectedOption) => {
        setCategory(selectedOption); // Update the selected category state

        console.log('val', selectedOption.label);
    };




    // const onDrop = (acceptedFiles) => {
    //     const imagePreviews = acceptedFiles.map((file) =>
    //         Object.assign(file, {
    //             preview: URL.createObjectURL(file),
    //         })
    //     );
    //     setImages([...images, ...imagePreviews]);
    // };

    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop,
    //     accept: "image/*",
    //     multiple: true,
    // });

   

    const categories = ['All', 'Trophy', 'Match', 'Sponsors'];

   

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);


    // const onDrop = (acceptedFiles) => {
    //     const mediaFiles = acceptedFiles.map((file) => {
    //         const mediaType = file.type.startsWith("image/") ? "image" : "video";
    //         return {
    //             media_type: mediaType,
    //             file: file, // Actual file object
    //             aspect_ratio: null, // Calculate this if necessary
    //             preview: URL.createObjectURL(file), // For display purposes only
    //         };
    //     });

    //     setImages((prevImages) => [...prevImages, ...mediaFiles]);
    // };


    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop,
    //     accept: "image/*,video/*", // Accept both images and videos
    //     multiple: true,
    // });

   

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // const staffIds = selectedOptions.map(option => option.id);
        const staffIds = selectedOptions.map(option => parseInt(option.id, 10));  // Convert IDs to integers

        console.log('Staff IDs:', staffIds);

        if (!title || !category || !description) {
            console.error("All fields are required");
            return;
        }

        const formData = new FormData();

        // Append basic fields
        formData.append('title', title);
        formData.append('description', description);
        formData.append('news_category', category.value);
        // formData.append('staff', JSON.stringify(staffIds));  // Use 'staff' instead of 'Staff'
        staffIds.forEach(id => {
            formData.append('staff', id); // Append each ID separately
        });


        const mediaFiles = files.map((img, index) => ({
            media_type: img.media_type,
            aspect_ratio: img.aspect_ratio || null,
        }));

        console.log(mediaFiles);

        files.forEach((img, index) => {
            formData.append(`file_${index}`, img.file); // Append the actual file object
            mediaFiles[index].file = img.file.name; // Use file name or the actual file
        });

        // Append media_files as JSON string
        formData.append('media_files', JSON.stringify(mediaFiles));

        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        try {
            // const response = await axios.post('http://localhost:8000/api/news/', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
            //     },
            // });
            // console.log('Post successful', response.data);
            // alert('News Post successful');
            // handleRefresh();
            // setTitle('');
            // setDescription('');
            // setImages([]);
            // setFiles([]);
            // setSelectedOptions([]);
            // setModalOpen(false);

            AuthService.setNews(formData).then((res) => {
               if(res.status === 201) {
                alert('News Post successful');
                handleRefresh();
                setTitle('');
                setDescription('');
                setImages([]);
                setFiles([]);
                setSelectedOptions([]);
                setModalOpen(false);
               }
            })
        } catch (error) {
            console.error('Error posting data', error.response?.data); // Log the error if any
        }
    };





    //new drag drop
    const [progress, setProgress] = useState({});
  
    const onDrop = (acceptedFiles) => {
      const mediaFiles = acceptedFiles.map((file) => {
        const mediaType = file.type.startsWith('image/') ? 'image' : 'video';
        return {
          media_type: mediaType,
          file: file, // Actual file object
          aspect_ratio: null, // Calculate this if necessary
          preview: URL.createObjectURL(file), // For display purposes only
        };
      });
  
      setFiles((prevFiles) => [...prevFiles, ...mediaFiles]);
      
      // Simulate upload progress for the new files
      mediaFiles.forEach((fileObj) => {
        simulateUpload(fileObj.file);
      });
    };
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'image/*,video/*', // Accept both images and videos
      multiple: true,
    });
  
    const simulateUpload = (file) => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const currentProgress = prevProgress[file.name] || 0;
          if (currentProgress >= 100) {
            clearInterval(interval);
            return { ...prevProgress, [file.name]: 100 };
          }
          return { ...prevProgress, [file.name]: currentProgress + 10 };
        });
      }, 300);
    };
  
    const removeFile = (fileName) => {
      setFiles((prevFiles) => prevFiles.filter((file) => file.file.name !== fileName));
      setProgress((prevProgress) => {
        const newProgress = { ...prevProgress };
        delete newProgress[fileName];
        return newProgress;
      });
    };




    const [activeTab, setActiveTab] = useState("All");

    const tabStyle = "cursor-pointer py-2 px-4 text-gray-600 hover:text-gray-900";
    const activeTabStyle = "border-b-2 border-orange-500 text-orange-500";
    const tabPanelStyle = "p-4";

    return (
        <div className="w-full h-full p-4 bg-white">
            {modalOpen ? (


                <div>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 style={{fontSize: '40px'}} className="text-base font-semibold leading-7 font-lg text-gray-900">Form</h2>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div className="flex flex-col w-full sm:flex-row sm:space-x-4 col-span-full">

                                    <div className="w-full ">
                                        <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">News Category*</label>
                                        <div className="mb-4 ">
                                            {/* <select
                                                name="category"
                                                className="w-full border-gray-400 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                                value={category} // Bind the select field to state
                                                onChange={(e) => setCategory(e.target.value)} // Update the state on change
                                                required
                                            >
                                                <option value="1">Trophy</option>
                                                <option value="2">Match</option>
                                                <option value="3">Sponsors</option>
                                            </select> */}

                                            <Select
                                                value={category}
                                                onChange={handleCategoryChange}
                                                styles={customStyles}
                                                options={categoryOptions} // Pass options to the select component
                                                isSearchable // Enable search functionality
                                                placeholder="Select a category..."
                                                className="w-full"
                                            />
                                        </div>
                                    </div>



                                    <div className="w-full ">
                                        <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Title*</label>
                                        <input name="title"
                                            value={title} // Bind the input to state
                                            onChange={(e) => setTitle(e.target.value)} // Update the state on change
                                            class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                                    </div>




                                    {/* 
                                    {category?.label !== "Special News"  && category?.label !== "Upcoming" &&  (
                                        <div className="w-full ">
                                        <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Title*</label>
                                        <input name="title"
                                            value={title} // Bind the input to state
                                            onChange={(e) => setTitle(e.target.value)} // Update the state on change
                                            class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                                    </div>
                                   )} */}




                                </div>



                                <div className="col-span-full">
                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                        Description
                                    </label>
                                    <div className="mt-2">
                                        <ReactQuill
                                            value={description}
                                            onChange={setDescription}
                                            className=" border-gray-200 rounded-xl overflow-hidden    shadow-sm ring-1 ring-inset w-full bg-slate-100  ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm text-lg sm:leading-6"
                                        />
                                    </div>
                                </div>




                                {category?.label == "Special Tag" && (
                                    <div className="col-span-full">

                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            id="tags-standard"
                                            options={staff}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <li {...props} className="flex items-center space-x-2 py-2 px-4 hover:bg-gray-100">
                                                    <Avatar src={option.icon} alt={option.label} />
                                                    <div>
                                                        <div className="text-sm font-semibold">{option.label}</div>
                                                        <div className="text-xs text-gray-500">{option.description}</div>
                                                    </div>
                                                </li>
                                            )}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={option.label}
                                                        avatar={<Avatar src={option.icon} alt={option.label} />}
                                                        {...getTagProps({ index })}
                                                        className="m-1"
                                                    />
                                                ))
                                            }
                                            value={selectedOptions}
                                            onChange={(event, newValue) => {
                                                setSelectedOptions(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select option..."
                                                    className="w-full"
                                                />
                                            )}
                                            className="w-full border border-gray-200 rounded-lg "
                                        // style={{ width: '400%' }}
                                        />
                                    </div>

                                )}




                                <div className="col-span-full">
                                    <label htmlFor="images" className="block text-sm font-medium leading-6 text-gray-900">
                                        Upload Images
                                    </label>
                                    {/* <div
                                        {...getRootProps()}
                                        className="mt-2 flex justify-center rounded-lg border  bg-slate-100 border-dashed border-gray-900/25 px-6 py-10"
                                    >
                                        <input {...getInputProps()} />
                                        <p className="text-sm  leading-6 text-gray-600">Drag & drop images here, or click to select files</p>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4 gap-4">
                                        {images.map((image, index) => (

                                            <div class="group relative mb-2 block  overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                                                <img src={image.preview} loading="lazy" alt="Photo by Austin Wade" class="  h-80 w-full  rounded-lg  object-cover object-center transition duration-200 group-hover:scale-110" />

                                                <div class="absolute left-0 bottom-2 flex gap-2">
                                                    <span class="rounded-r-lg bg-red-500 px-3 py-1.5 text-sm font-semibold uppercase tracking-wider text-white">image {index + 1}</span>
                                                </div>
                                            </div>

                                        ))}
                                    </div> */}
                                </div>




                            </div>









                                  
                            <div
                {...getRootProps()}
                className="cursor-pointer p-12 flex justify-center bg-slate-50 border border-dashed border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600"
            >
                <input {...getInputProps()} />
                <div className="text-center">
                <span className="inline-flex justify-center items-center size-16 bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
                    <svg className="shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" x2="12" y1="3" y2="15"></line>
                    </svg>
                </span>

                <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
                    <span className="pe-1 font-medium text-gray-800 dark:text-neutral-200">
                    Drop your files here or
                    </span>
                    <span className="bg-white font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600">
                    browse
                    </span>
                </div>

                <p className="mt-1 text-xs text-gray-400 dark:text-neutral-400">
                    Pick files up to 2MB.
                </p>
                </div>
            </div>

            {/* File Previews */}
            {/* <div className="mt-4 space-y-2">
                {files.map((fileObj, index) => (
                <div key={index} className="p-3 bg-white border border-solid border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600">
                    <div className="mb-1 flex justify-between items-center">
                    <div className="flex items-center gap-x-3">
                        
                        <span className="size-10 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500">
                        {fileObj.media_type === 'image' ? (
                            <img className="rounded-lg w-10 h-10 object-cover" src={fileObj.preview} alt={fileObj.file.name} />
                        ) : (
                            <video className="rounded-lg w-10 h-10 object-cover" src={fileObj.preview} controls />
                        )}
                        </span>
                        <div>
                        <p className="text-sm font-medium text-gray-800 dark:text-white">
                            {fileObj.file.name}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-neutral-500">
                            {(fileObj.file.size / 1024).toFixed(2)} KB
                        </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <button
                        type="button"
                        className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200"
                        onClick={() => removeFile(fileObj.file.name)}
                        >
                        <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M3 6h18"></path>
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                            <line x1="10" x2="10" y1="11" y2="17"></line>
                            <line x1="14" x2="14" y1="11" y2="17"></line>
                        </svg>
                        </button>
                    </div>
                    </div>

                    <div className="flex items-center gap-x-3 whitespace-nowrap">
                    <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                        <div
                        className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center transition-all duration-500 ${progress[fileObj.file.name] === 100 ? 'bg-green-500' : ''}`}
                        style={{ width: `${progress[fileObj.file.name] || 0}%` }}
                        />
                    </div>
                    <div className="w-10 text-end">
                        <span className="text-sm text-gray-800 dark:text-white">{progress[fileObj.file.name] || 0}%</span>
                    </div>
                    </div>
                </div>
                ))}
            </div> */}


<div className="mt-4 grid grid-cols-4 gap-x-2">
  {files.map((fileObj, index) => (
    <div key={index} className="relative mt-2 p-2 w-100 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
      <img
        className="mb-2  w-full object-cover rounded-lg"
        src={fileObj.preview}
        alt={fileObj.file.name}
      />

      <div className="mb-1 flex justify-between items-center gap-x-3 whitespace-nowrap">
        <div className="w-10">
          <span className="text-sm text-gray-800 dark:text-white">
            {(fileObj.file.size / 1024).toFixed(2)} KB
          </span>
        </div>

        <div className="flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200"
            onClick={() => removeFile(fileObj.file.name)}
          >
            <svg
              className="shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 6h18"></path>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              <line x1="10" x2="10" y1="11" y2="17"></line>
              <line x1="14" x2="14" y1="11" y2="17"></line>
            </svg>
          </button>
        </div>
      </div>

      <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
        <div
          className={`flex flex-col justify-center rounded-full bg-blue-600 text-xs text-white transition-all duration-500 ${progress[fileObj.file.name] === 100 ? 'bg-green-500' : ''}`}
          style={{ width: `${progress[fileObj.file.name] || 0}%` }}
        />
      </div>
    </div>
  ))}
</div>













                            
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button onClick={handleCloseModal} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>

                        {/* onClick={handleOpenModal} */}

                        <Button onClick={handleSubmit} color="green" className='text-white' >
                            Submit
                        </Button>
                    </div>
                </div>







            ) : (
                <>


                    <div className="flex justify-between items-center border-b mb-4">
                        <div className="flex">
                            {categories.map((category) => (
                                <h1
                                    key={category}
                                    className={`cursor-pointer text-2xl py-2 px-4 ${activeTab === category ? activeTabStyle : tabStyle}`}
                                    onClick={() => { setActiveTab(category); setSelectedCategory(category); }}
                                >
                                    {category}
                                </h1>
                            ))}
                        </div>
                        {/* <Button color="green" onClick={handleOpenModal}>
                            Add News
                        </Button> */}

                        {/* <button
                            onClick={handleOpenModal}
                            className="flex items-center font-bold py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300 ease-in-out"
                        >
                            <PlusIcon className="h-6 w-6 mr-2" />
                            Add News
                        </button> */}


                        <button
                            onClick={handleOpenModal}
                            className="flex items-center inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                        >
                            {/* <PlusIcon className="h-6 w-6 mr-2" /> */}
                            Add more
                        </button>
                    </div>

                    {/* <div className="bg-white py-6 sm:py-8 lg:py-12">
                        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
                            <div className="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
                                {filteredNews.map((news) => (
                                    <div key={news.id} className="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                                        <a href="#" className="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
                                            <img src={news.image} loading="lazy" alt="news" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                        </a>
                                        <div className="flex flex-col gap-2">
                                            <span className="text-sm text-gray-400">{news.timePosted.toLocaleDateString()}</span>
                                            <h2 className="text-xl font-bold text-gray-800">
                                                <a href="#" className="transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                                                    {news.title}
                                                </a>
                                            </h2>
                                            <p className="text-gray-500">{news.description}</p>
                                            <div>
                                                <a href="#" className="font-semibold text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700">
                                                    Read more
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}







                    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                            {/* {filteredNews.map((news) => ( */}
                            {newsData1.map((news) => (
                                <div key={news.id} className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                                    {/* <a href="/" aria-label="Article">
                                        <img
                                        src={news.image}
                                        className="object-cover w-full h-64 rounded"
                                        alt=""
                                        />
                                    </a> */}


                                    <a href="#" className="group relative block h-full w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-96 lg:w-full">
                                        {/* <img src={news.image} loading="lazy" alt="news" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" /> */}
                                        <img src={news.media_files[0]?.compressed_file} loading="lazy" alt="news" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                                    </a>

                                    <div className="py-5">
                                        <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                        {timeDifference(news.created_at)} 
                                        {/* {formatDate(news.created_at)} */}
                                        </p>
                                        <a
                                            href="/"
                                            aria-label="Article"
                                            className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                                        >
                                            <p className="text-2xl font-bold leading-5">{truncateText(news.title, 3)}</p>
                                        </a>
                                        <p className="mb-4 text-gray-700">
                                        {truncateText(stripHTML(news.description), 15)}                                        </p>
                                        <div className="flex space-x-4">
                                            <a
                                                href="/"
                                                aria-label="Likes"
                                                className="flex items-start text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700 group"
                                            >
                                                <div className="mr-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        className="w-5 h-5 text-gray-600 transition-colors duration-200 group-hover:text-deep-purple-accent-700"
                                                    >
                                                        <polyline
                                                            points="6 23 1 23 1 12 6 12"
                                                            fill="none"
                                                            strokeMiterlimit="10"
                                                        />
                                                        <path
                                                            d="M6,12,9,1H9a3,3,0,0,1,3,3v6h7.5a3,3,0,0,1,2.965,3.456l-1.077,7A3,3,0,0,1,18.426,23H6Z"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeMiterlimit="10"
                                                        />
                                                    </svg>
                                                </div>
                                                <p className="font-semibold">{formatLikeCount(news.like_count)}</p>
                                            </a>
                                            <a
                                                href="/"
                                                aria-label="Comments"
                                                className="flex items-start text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700 group"
                                            >
                                                <div className="mr-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        className="w-5 h-5 text-gray-600 transition-colors duration-200 group-hover:text-deep-purple-accent-700"
                                                    >
                                                        <polyline
                                                            points="23 5 23 18 19 18 19 22 13 18 12 18"
                                                            fill="none"
                                                            strokeMiterlimit="10"
                                                        />
                                                        <polygon
                                                            points="19 2 1 2 1 14 5 14 5 19 12 14 19 14 19 2"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeMiterlimit="10"
                                                        />
                                                    </svg>
                                                </div>
                                                <p className="font-semibold">0</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>

                        {nextPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={handleLoadMore}
                                className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                            >
                                Load more
                            </button>
                        </div>
                    )}
                    </div>









                </>
            )}


        </div>
    );
};

export default NewsPage;








// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';

// const NewsPage = () => {
//   const [files, setFiles] = useState([]);
//   const [progress, setProgress] = useState({});

//   const onDrop = (acceptedFiles) => {
//     const mediaFiles = acceptedFiles.map((file) => {
//       const mediaType = file.type.startsWith('image/') ? 'image' : 'video';
//       return {
//         media_type: mediaType,
//         file: file, // Actual file object
//         aspect_ratio: null, // Calculate this if necessary
//         preview: URL.createObjectURL(file), // For display purposes only
//       };
//     });

//     setFiles((prevFiles) => [...prevFiles, ...mediaFiles]);
    
//     // Simulate upload progress for the new files
//     mediaFiles.forEach((fileObj) => {
//       simulateUpload(fileObj.file);
//     });
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: 'image/*,video/*', // Accept both images and videos
//     multiple: true,
//   });

//   const simulateUpload = (file) => {
//     const interval = setInterval(() => {
//       setProgress((prevProgress) => {
//         const currentProgress = prevProgress[file.name] || 0;
//         if (currentProgress >= 100) {
//           clearInterval(interval);
//           return { ...prevProgress, [file.name]: 100 };
//         }
//         return { ...prevProgress, [file.name]: currentProgress + 10 };
//       });
//     }, 300);
//   };

//   const removeFile = (fileName) => {
//     setFiles((prevFiles) => prevFiles.filter((file) => file.file.name !== fileName));
//     setProgress((prevProgress) => {
//       const newProgress = { ...prevProgress };
//       delete newProgress[fileName];
//       return newProgress;
//     });
//   };

//   return (
//     <div>
//       {/* Drag and Drop Area */}
//       <div
//         {...getRootProps()}
//         className="cursor-pointer p-12 flex justify-center bg-white border border-dashed border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600"
//       >
//         <input {...getInputProps()} />
//         <div className="text-center">
//           <span className="inline-flex justify-center items-center size-16 bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
//             <svg className="shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//               <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
//               <polyline points="17 8 12 3 7 8"></polyline>
//               <line x1="12" x2="12" y1="3" y2="15"></line>
//             </svg>
//           </span>

//           <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
//             <span className="pe-1 font-medium text-gray-800 dark:text-neutral-200">
//               Drop your files here or
//             </span>
//             <span className="bg-white font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600">
//               browse
//             </span>
//           </div>

//           <p className="mt-1 text-xs text-gray-400 dark:text-neutral-400">
//             Pick files up to 2MB.
//           </p>
//         </div>
//       </div>

//       {/* File Previews */}
//       <div className="mt-4 space-y-2">
//         {files.map((fileObj, index) => (
//           <div key={index} className="p-3 bg-white border border-solid border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600">
//             <div className="mb-1 flex justify-between items-center">
//               <div className="flex items-center gap-x-3">
//                 {/* Thumbnail */}
//                 <span className="size-10 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500">
//                   {fileObj.media_type === 'image' ? (
//                     <img className="rounded-lg w-10 h-10 object-cover" src={fileObj.preview} alt={fileObj.file.name} />
//                   ) : (
//                     <video className="rounded-lg w-10 h-10 object-cover" src={fileObj.preview} controls />
//                   )}
//                 </span>
//                 <div>
//                   <p className="text-sm font-medium text-gray-800 dark:text-white">
//                     {fileObj.file.name}
//                   </p>
//                   <p className="text-xs text-gray-500 dark:text-neutral-500">
//                     {(fileObj.file.size / 1024).toFixed(2)} KB
//                   </p>
//                 </div>
//               </div>
//               <div className="flex items-center gap-x-2">
//                 <button
//                   type="button"
//                   className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200"
//                   onClick={() => removeFile(fileObj.file.name)}
//                 >
//                   <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//                     <path d="M3 6h18"></path>
//                     <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
//                     <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
//                     <line x1="10" x2="10" y1="11" y2="17"></line>
//                     <line x1="14" x2="14" y1="11" y2="17"></line>
//                   </svg>
//                 </button>
//               </div>
//             </div>

//             {/* Progress Bar */}
//             <div className="flex items-center gap-x-3 whitespace-nowrap">
//               <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
//                 <div
//                   className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center transition-all duration-500 ${progress[fileObj.file.name] === 100 ? 'bg-green-500' : ''}`}
//                   style={{ width: `${progress[fileObj.file.name] || 0}%` }}
//                 />
//               </div>
//               <div className="w-10 text-end">
//                 <span className="text-sm text-gray-800 dark:text-white">{progress[fileObj.file.name] || 0}%</span>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default NewsPage;
