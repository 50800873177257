import React, { useState } from "react";

import { Input, Textarea, Select, Option, Button } from "@material-tailwind/react";
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

// Define custom tab styles
const tabStyle = "cursor-pointer py-2 px-4 text-gray-600 hover:text-gray-900";
const activeTabStyle = "border-b-2 border-orange-500 text-orange-500";
const tabPanelStyle = "p-4";

function VideoPage() {
    const [activeTab, setActiveTab] = useState("post");
    const [open, setOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    // Toggle modal visibility
    const handleOpen = () => setModalOpen(!modalOpen);

    // Handle video selection
    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
    };

    // Handle video addition
    const handleAddVideo = () => {
        if (selectedVideo) {
            alert(`Video added: ${selectedVideo.title}`);
            handleOpen();
        } else {
            alert("Please select a video.");
        }
    };

    // Tabs and videos data
    const tabs = [
        { label: "Post", value: "post" },
        { label: "Training", value: "training" },
        { label: "Video Clip", value: "clip" },
    ];

    const videos = [
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://www.w3schools.com/html/mov_bbb.mp4", // Example video URL
        },
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://www.w3schools.com/html/mov_bbb.mp4", // Example video URL
        },
        {
            title: "How to Dribble",
            description: "Learn how to dribble like a pro in this tutorial.",
            timeAgo: "2 hours ago",
            category: "post",
            url: "https://www.w3schools.com/html/mov_bbb.mp4", // Example video URL
        },

        {
            title: "Intensive Training Session",
            description: "A glimpse into our latest training routine.",
            timeAgo: "1 day ago",
            category: "training",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Intensive Training Session",
            description: "A glimpse into our latest training routine.",
            timeAgo: "1 day ago",
            category: "training",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Intensive Training Session",
            description: "A glimpse into our latest training routine.",
            timeAgo: "1 day ago",
            category: "training",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Intensive Training Session",
            description: "A glimpse into our latest training routine.",
            timeAgo: "1 day ago",
            category: "training",
            url: "https://www.w3schools.com/html/movie.mp4", // Example video URL
        },
        {
            title: "Intensive Training Session",
            description: "A glimpse into our latest training routine.",
            timeAgo: "1 day ago",
            category: "training",
            url: "https://www.w3schools.com/html/movie.mp4", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
        {
            title: "Best Goals Compilation",
            description: "Watch the top 10 goals of the season.",
            timeAgo: "3 days ago",
            category: "clip",
            url: "https://video.twimg.com/amplify_video/1830641896928014336/vid/avc1/1920x1080/LtsTx7-X2bzLNAhb.mp4?tag=16", // Example video URL
        },
    ];



    const [formData, setFormData] = useState({
        title: '',
        description: '',
        videoType: '',
        coverPhoto: null,
        videoFile: null,
    });
    const [coverPreview, setCoverPreview] = useState(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (name === 'coverPhoto' && file) {
            setCoverPreview(URL.createObjectURL(file));
        }

        setFormData({
            ...formData,
            [name]: file,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to a backend API
        console.log(formData);
    };


    return (
        <>
            {modalOpen ? (


                <div className="p-8 bg-gray-100 min-h-screen">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-3xl font-semibold text-gray-800">Add Video</h1>
                        
                        <button
                            onClick={() => setModalOpen(false)}
                            className="flex font-bold items-center py-2 px-4 bg-orange-500 text-white rounded-lg hover:bg-green-600  transition-all duration-300 ease-in-out"
                        >
                            <ArrowLeftIcon className="h-6 w-6 mr-2" />
                            GO BACK
                        </button>
                    </div>
                    {/* <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 shadow-lg rounded-lg"> */}
                    <form onSubmit={handleSubmit} className=" mx-auto bg-white p-6 shadow-lg rounded-lg">





                        <div class="bg-white  sm:py-8 lg:py-12">
                            <div class="mx-auto max-w-screen-2xl px-4 md:px-8">

                                <form class=" grid  gap-4 ">
                                    <div>
                                        <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Video Type*</label>
                                        <div className="mb-4 ">
                                            <select
                                                placeholder={"Select Video Type"}
                                                name="videoType"
                                                className="w-full border-gray-400 input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                                                // value={formData.videoType}
                                                // onChange={(value) => setFormData({ ...formData, videoType: value })}
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="Tutorial">Post</option>
                                                <option value="Entertainment">Training</option>
                                                <option value="Documentary">Video Clip</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Title*</label>
                                        <input name="title" value={formData.title} onChange={handleChange} class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                                    </div>

                                    <div class="sm:col-span-2">
                                        <label for="message" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Description*</label>
                                        <textarea name="description"
                                            value={formData.description}
                                            onChange={handleChange} class="h-64 w-full rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"></textarea>
                                    </div>



                                    <div class="sm:col-span-2">
                                        <label for="company" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Cover Photo</label>
                                        <input type="file" name="coverPhoto" accept="image/*" onChange={handleFileChange} required class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                                    </div>


                                    {coverPreview && (
                                        <div className="mb-4 ">
                                            {/* <label className="block text-gray-700 mb-2">Cover Photo Preview:</label> */}
                                            <img src={coverPreview} alt="Cover Preview" className="w-80 h-80 object-cover rounded-md     inset-0 object-center transition duration-200" />
                                        </div>
                                    )}



                                    <div class="sm:col-span-2">
                                        <label for="company" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Company</label>
                                        <input type="file"
                                            name="videoFile"
                                            accept="video/*"
                                            onChange={handleFileChange} class="w-full input-js rounded border bg-gray-100 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                                    </div>






                                    <div class="flex items-center justify-between sm:col-span-2">
                                        <button class="inline-block rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Send</button>

                                        <span class="text-sm text-gray-500">*Required</span>
                                    </div>

                                    <p class="text-xs text-gray-400">By signing up to our newsletter you agree to our <a href="#" class="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy Policy</a>.</p>
                                </form>
                            </div>
                        </div>

















                        {/* <div className="text-right">
                            <Button type="submit" color="blue">
                                Add Video
                            </Button>
                        </div> */}
                    </form>
                </div>


            ) : (
                <div className={`p-8 bg-gray-100 min-h-screen `}>
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-3xl font-semibold text-gray-800">Videos</h1>
                        {/*  */}

                        <button
                            onClick={handleOpen}
                            className="flex items-center py-2 px-4 font-bold bg-green-500 text-white rounded-lg hover:bg-green-600 focus:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300 ease-in-out"
                        >
                            <PlusIcon className="h-6 w-6 mr-2" />
                            ADD VIDEO
                        </button>
                    </div>

                    <div className="bg-white shadow-md rounded-lg">
                        <div className="flex border-b">
                            {tabs.map(({ label, value }) => (
                                <h1
                                    key={value}
                                    className={`cursor-pointer text-2xl py-2 px-4 ${activeTab === value ? activeTabStyle : tabStyle
                                        }`}
                                    onClick={() => setActiveTab(value)}
                                >
                                    {label}
                                </h1>
                            ))}
                        </div>

                        <div className="p-4">
                            {tabs.map(({ value }) => (
                                <div
                                    key={value}
                                    className={`${tabPanelStyle} ${activeTab === value ? "block" : "hidden"
                                        }`}
                                >
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        {videos
                                            .filter((video) => video.category === value)
                                            .map((video, index) => (
                                                <div
                                                    key={index}
                                                    className="w-full shadow-lg p-4 bg-white rounded cursor-pointer"
                                                    onClick={() => handleVideoSelect(video)}
                                                >
                                                    <div className="relative">
                                                        <video
                                                            controls
                                                            className="w-full rounded-lg shadow-lg"
                                                        >
                                                            <source src={video.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h2 className="text-xl font-medium text-blue-gray-800">
                                                            {video.title}
                                                        </h2>
                                                        <p className="text-gray-600 mt-2">
                                                            {video.description}
                                                        </p>
                                                        <p className="text-gray-500 mt-1 text-sm">
                                                            {video.timeAgo}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


            )}
        </>
    );
}

export default VideoPage;
