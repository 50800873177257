import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/24/outline';
import { timeDifference } from '../utils/helperFunctions';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import AuthService from '../services/auth/auth_service';

const SpecialNews = () => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null); // Single image
    const [file, setFile] = useState(null);

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextPage, setNextPage] = useState(1);
    const [refreshing, setRefreshing] = useState(false);



    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setImage(null); // Clear image when closing modal
    };

    // Handle drag and drop of images (allow only one)
    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: { 'image/*': [] },
    //     onDrop: (acceptedFiles) => {
    //         // Replace the existing image with the new one
    //         setImage(
    //             Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
    //         );
    //     },
    //     multiple: false, // Ensure only one image can be selected
    // });

    // Remove image from preview
    const handleRemoveImage = () => {
        setImage(null);
    };

    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = async (page = 1) => {
        setLoading(true);
        // try {
        //     const response = await fetch(`http://localhost:8000/v1/special-news/?page=${page}`, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //         },
        //     });

        //     if (!response.ok) {
        //         throw new Error(`Error fetching news: ${response.statusText}`);
        //     }

        //     const data = await response.json();
        //     console.log("News data:", data);

        //     // Append new data to the existing data

        // if (page === 1) {
        //     // Replace data when it's the first page (avoid duplication)
        //     setPosts(data.results);
        // } else {
        //     // Append new data when loading more pages
        //     setPosts(prevData => [...prevData, ...data.results]);
        // }

        //     // Handle the next page URL
        //     setNextPage(data.next ? page + 1 : null); 

        // } catch (error) {
        //     console.error("Error fetching news:", error);
        //     // You can set some error state to notify users
        // } finally {
        //     setLoading(false);
        // }

        AuthService.getSpecialNews(page).then((response) => {
            if (response.status === 200) {
                if (page === 1) {
                    // Replace data when it's the first page (avoid duplication)
                    setPosts(response.data.results);
                } else {
                    // Append new data when loading more pages
                    setPosts(prevData => [...prevData, ...response.data.results]);
                }

                // Handle the next page URL
                setNextPage(response.data.next ? page + 1 : null);
            }
            setLoading(false);
        })
    };






    const handleSubmit = async () => {
        // Assuming `file` is wrapped in an object, you should extract the actual file from it
        const actualFile = file?.file;  // Extract the file object from the wrapper

        if (!actualFile || !(actualFile instanceof File)) {
            console.error("No file selected or invalid file object.");
            return;
        }

        const formData = new FormData();
        formData.append('image', actualFile);  // Send the extracted file

        // try {
        //     const config = {
        //         headers: {
        //             'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODgyMjQ3MTA3LCJpYXQiOjE3Mjg2NDcxMDcsImp0aSI6IjM4ZWYzMzYxZjYwYzRmNDU5MTc4OTU0OTJhYmNlYzU2IiwidXNlcl9pZCI6MX0.3Z0O2f0vePhXAH-hlSlO-X4E7nvlo8QVq9dzRoItYn8`,
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     };

        //     await axios.post('http://localhost:8000/v1/special-news/', formData, config);
        //     handleRefresh()  // Refresh the data after submission
        //     setFile(null);  // Reset file state after successful submission
        //     handleClose();  // Close any open modals or forms
        // } catch (error) {
        //     console.error("Error submitting image:", error.response ? error.response.data : error.message);
        // }

   
    AuthService.setSpecialNews(formData).then((response) => {
        if (response.status === 201) {
            handleRefresh()  // Refresh the data after submission
            setFile(null);  // Reset file state after successful submission
            handleClose();  // Close any open modals or forms
        }
    })
        .catch((error) => {
            console.error("Error submitting image:", error.response ? error.response.data : error.message);
        });

    };












    // Triggered when user reaches end of the list
    // const loadMoreData = () => {
    //     if (!loading && nextPage) {
    //         fetchData();
    //     }
    // };


    const loadMoreData = () => {
        if (nextPage && !loading) {
            fetchData(nextPage); // Fetch the next page of news
        }
    };

    const handleRefresh = () => {
        setRefreshing(true);
        setNextPage(1); // Reset to the first page on refresh
        setPosts([]); // Clear the previous data
        fetchData(1).finally(() => setRefreshing(false)); // Fetch fresh data on refresh
    };















    //new drag
    const [progress, setProgress] = useState(0);

    const onDrop = (acceptedFiles) => {
        const uploadedFile = acceptedFiles[0]; // Only handle the first file
        const mediaType = uploadedFile.type.startsWith('image/') ? 'image' : 'video';
        const newFile = {
            media_type: mediaType,
            file: uploadedFile, // Actual file object
            preview: URL.createObjectURL(uploadedFile), // For display purposes only
        };

        setFile(newFile); // Replace the current file with the new one
        setProgress(0); // Reset progress for the new file

        // Simulate upload progress for the new file
        simulateUpload(uploadedFile);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept only images for single upload
        multiple: false, // Only allow one file at a time
    });

    const simulateUpload = (file) => {
        const interval = setInterval(() => {
            setProgress((currentProgress) => {
                if (currentProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return currentProgress + 10;
            });
        }, 300);
    };

    const removeFile = () => {
        setFile(null); // Remove the uploaded image
        setProgress(0); // Reset progress
    };











    return (
        <div className="w-full h-full p-4 bg-gray-100">
            <div className="bg-white py-6 sm:py-8 lg:py-12">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <div className="mb-6 flex items-end justify-between gap-4">
                        <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">Special News</h2>
                        <button
                            onClick={handleOpen}
                            className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                        >
                            Add more
                        </button>
                    </div>

                    <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
                        {/* News cards */}
                        {posts.map((post, index) => (
                            <div key={index}>
                                <a href="#" className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                                    <img src={post.compressed_image} loading="lazy" alt="Special News" className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                </a>
                                <div className="flex flex-col">
                                    <span className="text-gray-500">{timeDifference(post.created_at)}</span>
                                    <a href="#" className="text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl">Created by</a>
                                </div>
                            </div>
                        ))}
                    </div>

                    {nextPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={loadMoreData}
                                className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
                            >
                                Load more
                            </button>
                        </div>
                    )}

                    {/* Modal */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Add Special News</DialogTitle>
                        <DialogContent>
                            {/* <div className="my-4">
                                <div
                                    {...getRootProps({
                                        className:
                                            'border-dashed border-2 p-4 text-center bg-gray-50',
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <div className="flex flex-col items-center justify-center py-10 text-center">
                                        <svg className="w-6 h-6 mr-1 text-current-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <p className="m-0">Drag your file here or click to select one image.</p>
                                    </div>
                                </div>
                                {image && (
                                    <div className="mt-4 relative">
                                        <img
                                            src={image.preview}
                                            alt={image.name}
                                            className="h-32 w-32 object-cover"
                                        />
                                        <button
                                            onClick={handleRemoveImage}
                                            className="absolute top-0 right-0 p-1 text-red-500"
                                        >
                                            ✖
                                        </button>
                                    </div>
                                )}
                            </div> */}




                            <div
                                {...getRootProps()}
                                className="cursor-pointer p-12 flex justify-center bg-white border border-dashed border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600"
                            >
                                <input {...getInputProps()} />
                                <div className="text-center">
                                    <span className="inline-flex justify-center items-center size-16 bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
                                        <svg className="shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="17 8 12 3 7 8"></polyline>
                                            <line x1="12" x2="12" y1="3" y2="15"></line>
                                        </svg>
                                    </span>

                                    <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
                                        <span className="pe-1 font-medium text-gray-800 dark:text-neutral-200">
                                            Drop your file here or
                                        </span>
                                        <span className="bg-white font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600">
                                            browse
                                        </span>
                                    </div>

                                    <p className="mt-1 text-xs text-gray-400 dark:text-neutral-400">
                                        Pick an image up to 2MB.
                                    </p>
                                </div>
                            </div>

                            {/* Image Preview */}
                            {file && (
                                // <div className="mt-4 space-y-2">
                                //   <div className="p-3 bg-white border border-solid border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600">
                                //     <div className="mb-1 flex justify-between items-center">
                                //       <div className="flex items-center gap-x-3">

                                //         <span className="size-10 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500">
                                //           {file.media_type === 'image' && (
                                //             <img className="rounded-lg w-10 h-10 object-cover" src={file.preview} alt={file.file.name} />
                                //           )}
                                //         </span>
                                //         <div>
                                //           <p className="text-sm font-medium text-gray-800 dark:text-white">
                                //             {file.file.name}
                                //           </p>
                                //           <p className="text-xs text-gray-500 dark:text-neutral-500">
                                //             {(file.file.size / 1024).toFixed(2)} KB
                                //           </p>
                                //         </div>
                                //       </div>
                                //       <div className="flex items-center gap-x-2">
                                //         <button
                                //           type="button"
                                //           className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200"
                                //           onClick={removeFile}
                                //         >
                                //           <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                //             <path d="M3 6h18"></path>
                                //             <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                //             <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                //             <line x1="10" x2="10" y1="11" y2="17"></line>
                                //             <line x1="14" x2="14" y1="11" y2="17"></line>
                                //           </svg>
                                //         </button>
                                //       </div>
                                //     </div>


                                //     <div className="flex items-center gap-x-3 whitespace-nowrap">
                                //       <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                                //         <div
                                //           className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center transition-all duration-500 ${progress === 100 ? 'bg-green-500' : ''}`}
                                //           style={{ width: `${progress}%` }}
                                //         />
                                //       </div>
                                //       <div className="w-10 text-end">
                                //         <span className="text-sm text-gray-800 dark:text-white">{progress}%</span>
                                //       </div>
                                //     </div>
                                //   </div>
                                // </div>




                                <div className="mt-4 grid grid-cols-4 gap-x-2">
                                    <div className="relative mt-2 p-2 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                                        {file.media_type === 'image' && (
                                            <img
                                                className="mb-2 w-full object-cover rounded-lg"
                                                src={file.preview} alt={file.file.name}
                                            />



                                        )}

                                        <div className="mb-1 flex justify-between items-center gap-x-3 whitespace-nowrap">
                                            <div className="w-10">
                                                <span className="text-sm text-gray-800 dark:text-white">
                                                    {(file.file.size / 1024).toFixed(2)} KB
                                                </span>
                                            </div>

                                            <div className="flex items-center gap-x-2">
                                                <button
                                                    type="button"
                                                    className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200"
                                                    onClick={removeFile}
                                                >
                                                    <svg
                                                        className="shrink-0 size-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M3 6h18"></path>
                                                        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                                        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                                        <line x1="10" x2="10" y1="11" y2="17"></line>
                                                        <line x1="14" x2="14" y1="11" y2="17"></line>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                                            <div
                                                className={`flex flex-col justify-center rounded-full bg-blue-600 text-xs text-white transition-all duration-500 ${progress === 100 ? 'bg-green-500' : ''}`}
                                                style={{ width: `${progress}%` }}
                                            />
                                        </div>


                                        {/* <div className="flex items-center gap-x-3 whitespace-nowrap">
                                      <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                                        <div
                                          className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center transition-all duration-500 ${progress === 100 ? 'bg-green-500' : ''}`}
                                          style={{ width: `${progress}%` }}
                                        />
                                      </div>
                                      <div className="w-10 text-end">
                                        <span className="text-sm text-gray-800 dark:text-white">{progress}%</span>
                                      </div>
                                    </div> */}



                                    </div>
                                </div>



                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default SpecialNews;

