import API from "../api";
import axios from "axios";

// const base_url = 'http://localhost:8000/'
const base_url = 'https://yangademo.ngrok.app/';
export default class AuthService {
  static async login(payload) {
    try {
        const response = await axios.post(
            `${base_url}api/auth/web/login/`,
            payload,
            { headers: { "Authorization": null } }
        );
        return response; // Return the response on success
    } catch (error) {
        // Log the error and rethrow it for handling in the calling function
        console.error('Login error:', error); 
        throw error; // Rethrow the error
    }
}

static async verifyOTP(payload) {
  try {
      const response = await axios.post(
          `${base_url}api/auth/web/verify-otp/`,
          payload,
          { headers: { "Authorization": null } }
      );
      return response; // Return the response on success
  } catch (error) {
      // Log the error and rethrow it for handling in the calling function
      console.error('Login error:', error); 
      throw error; // Rethrow the error
  }
}


  // static verifyOTP(payload) {
 
  //   return axios.post("http://localhost:8000/api/auth/web/verify-otp/", payload, { headers: { "Authorization": null }}).catch((e) => console.log(e));
  // }


  static getNews(page) {
    return API.ax.get(`api/news/?page=${page}`).catch((e) => console.log(e));
  }

  static setNews(data) {
    return API.ax.post("api/news/", data).catch((e) => console.log(e));
  }

  static getStaff() {
    return API.ax.get(`v1/staff`).catch((e) => console.log(e));
  }

  static getCategories() {
    return API.ax.get(`v1/news-categories/`).catch((e) => console.log(e));
  }

  static getSpecialNews(page) {
    return API.ax.get(`v1/special-news/?page=${page}`).catch((e) => console.log(e));
  }

  static setSpecialNews(data) {
    return API.ax.post("v1/special-news/", data).catch((e) => console.log(e));
  }

  static getUpcomingNews(page) {
    return API.ax.get(`v1/upcoming-news/?page=${page}`).catch((e) => console.log(e));
  }

  static getPlayers() {
    return API.ax.get(`api/players/grouped/`).catch((e) => console.log(e));
  }

  static setUpcomingNews(data) {
    return API.ax.post("v1/upcoming-news/create/", data).catch((e) => console.log(e));
  }

  static getSponsers(page) {
    return API.ax.get(`v1/sponsers/?page=${page}`).catch((e) => console.log(e));
  }

  static setSponsers(data) {
    return API.ax.post("v1/sponsers/", data).catch((e) => console.log(e));
  }









}
